import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import c from "classnames";
import { section, h2 } from "tags";

import SectionContent from "./SectionContent";

class Section extends Component {
  render() {
    const {
      title,
      stepData: { position, status, locked: stepIsLocked, type: name },
      stepIsActive,
      viewer,
      editor
    } = this.props;

    const stepIsCompleted = status === "completed";

    const sectionProps = {
      className: c(stepIsActive && "active"),
      "data-name": name
    };

    const editButton = makeEditButton(this.props);

    return section(".checkout", sectionProps, [
      editButton,
      h2(".section-title", `${position}. ${title}`),
      h(SectionContent, {
        title,
        stepIsActive,
        stepIsCompleted,
        stepIsLocked,
        viewer,
        editor
      })
    ]);
  }
}

Section.propTypes = {
  title: PropTypes.string.isRequired, // display name - e.g., "Registration Records"
  stepData: PropTypes.shape({
    type: PropTypes.string.isRequired, // short name - e.g., "registration"
    position: PropTypes.number.isRequired,
    status: PropTypes.oneOf(["pending", "completed"]),
    locked: PropTypes.bool
  }).isRequired,
  stepIsActive: PropTypes.bool.isRequired,
  viewer: PropTypes.func.isRequired,
  editor: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

function makeEditButton({ stepIsEditable, stepIsActive, onEdit, editButton, forceEdit }) {
  const defaultButton =
    editButton ||
    h(
      "button.btn-link.btn-edit",
      {
        onClick: onEdit
      },
      "Edit"
    );
  const button = editButton || defaultButton;
  return ((stepIsEditable && !stepIsActive) || forceEdit) && button;
}

export default Section;
