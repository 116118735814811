import { Component } from "react";
import PropTypes from "prop-types";
import { h, fasIcon, numberToCurrency, pluralize, updateHeaderCartItemCount } from "h";
import { MiniCart, initCartData } from "./MiniCart";
import AsideHelp from "./AsideHelp";
import { Box, BoxItem } from "./Box";
import { TieredPriceInfoToolTip } from "../form/Tooltip";

class RenewResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      title: props.title,
      cart: initCartData(props.cart),
      supportOpen: props.supportOpen,
      domains: props.domains,

      terms: {},
      inCart: [],

      closeDomain: name => this.closeDomain(name),
      updateTerm: (name, term) => this.updateTerm(name, term),
      addToCart: (name, term) => this.addToCart(name, term),
      removeFromCart: name => this.removeFromCart(name)
    };
  }

  closeDomain(name) {
    $.post("/renew/remove_from_checklist", { name }).done(result => {
      this.setState({ items: result.results.items, title: result.results.title });
    }, "json");
  }

  updateTerm(name, term) {
    const terms = Object.assign({}, this.state.terms, { [name]: parseInt(term) });
    this.setState({ terms });
  }

  addToCart(name, term) {
    const request = this.state.inCart.includes(name)
      ? $.post("/cart/remove_cart", { name })
      : $.post("/renew/add_to_cart", { name, term });

    request.done(result => {
      if (result.succeeded) {
        const cart = initCartData(result);
        this.setCart(result);
        if (window.updateCart) {
          window.updateCart(cart);
        }
      } else {
        alert(result.error || "Sorry, that domain can’t be renewed right now.");
      }
    });
  }

  removeFromCart(name) {
    $.post("/cart/remove_cart", { name }, result => this.setCart(result));
  }

  setCart(result) {
    const cart = initCartData(result);
    const names = cart.items.map(i => i.name);
    this.setState({ cart: cart, inCart: names }, () =>
      updateHeaderCartItemCount(cart.items.length)
    );
  }

  render() {
    return h(RenewResultsPage, this.state);
  }
}

RenewResults.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  cart: PropTypes.object.isRequired,
  supportOpen: PropTypes.bool.isRequired,
  domains: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

function RenewResultsPage(props) {
  return h("div", [
    h("h1", props.title),
    h(".left", [h(SearchForm, { domains: props.domains }), h(ResultList, props)]),
    h(".right", [
      h(MiniCart, {
        ...props.cart,
        onRemoveFromCart: props.removeFromCart
      }),
      h(AsideHelp, { supportOpen: props.supportOpen })
    ])
  ]);
}

RenewResultsPage.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  cart: PropTypes.object.isRequired,
  supportOpen: PropTypes.bool.isRequired,
  domains: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  terms: PropTypes.object.isRequired,
  inCart: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  closeDomain: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired
};

class ResultList extends Component {
  renderItem(item) {
    const { closeDomain, updateTerm, addToCart } = this.props;
    const inCart = this.props.inCart.includes(item.name);

    if (item.status === "renewable") {
      const overriddenTerm = this.props.terms[item.name];
      const termUpdate = overriddenTerm ? { term: overriddenTerm } : {};
      return h(Renewable, {
        ...{ ...item, ...termUpdate },
        inCart,
        closeDomain: () => closeDomain(item.name),
        updateTerm,
        addToCart
      });
    } else {
      return h(NotRenewable, {
        name: item.name,
        inCart,
        reason: reasonForStatus(item.status, item.name),
        status: item.status,
        closeDomain: () => closeDomain(item.name)
      });
    }

    function reasonForStatus(status, domainName) {
      if (status === "redeemable") {
        return "To recover this domain, please call the Hover Help Center.";
      } else if (status === "not_renewable") {
        return "Sorry, that domain can’t be renewed right now.";
      } else if (status === "automatic_renewals") {
        const ext = extension(domainName).toUpperCase();
        return `Thanks to .${ext} registry policies, .${ext} domains can’t be renewed manually. If you want this domain to renew before it expires, make sure that you have auto-renew enabled and Hover will renew it for you.`;
      } else if (status === "not_registered") {
        return "This domain is not registered with Hover.";
      }
    }
  }
  render() {
    const { items } = this.props;
    return h(".results", items.map((i) => this.renderItem(i)));
  }
}

ResultList.propTypes = {
  items: PropTypes.array.isRequired,
  terms: PropTypes.object.isRequired,
  inCart: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  closeDomain: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired
};

function extension(domainName) {
  const parts = domainName.split(".");
  return parts[parts.length - 1];
}

class Renewable extends Component {
  renderMailbox(component) {
    const description = "You have " + component.description.replace("+", "");
    return h(BoxItem, [
      h(".info.sub", description),
      h(".price", numberToCurrency(component.price * this.props.term))
    ]);
  }

  onChange(term) {
    this.props.updateTerm(this.props.name, term);
  }
  render() {
    const { name, term, price, components, inCart } = this.props;
    const { closeDomain, updateTerm, addToCart } = this.props;
    const mailboxes = components.map(c => this.renderMailbox(c));
    const onClick = e => this.props.addToCart(name, term);

    return h(
      Box,
      {
        className: "renewable",
        title: name,
        onClose: closeDomain,
        key: name,
        props: { "data-status": "renewable" }
      },
      [
        h(BoxItem, [
          h(".info", [
            fasIcon("check-circle", { className: "status" }),
            h(RenewableInfo, this.props)
          ]),
          h(".tools", [
            h(".price", numberToCurrency(price * term))
          ])
        ]),
        ...mailboxes,
        h(BoxItem, [
          h(
            "button.btn.btn-primary.add",
            { onClick },
            inCart ? "Added" : "Add to cart"
          )
        ])
      ]
    );
  }
}

Renewable.propTypes = {
  name: PropTypes.string.isRequired,
  term: PropTypes.number.isRequired,
  termOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  price: PropTypes.number.isRequired,
  components: PropTypes.array.isRequired,
  inCart: PropTypes.bool.isRequired,
  closeDomain: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired
};

const RenewableInfo = props =>
  props.tieredPrice ? renderTieredPrice() : renderRegularPrice();

RenewableInfo.propTypes = {
  tieredPrice: PropTypes.bool.isRequired
};

function renderTieredPrice() {
  return h("span", [
    "This tiered price domain",
    h(TieredPriceInfoToolTip),
    " is available for renewal."
  ]);
}

function renderRegularPrice() {
  return h("span", "This domain is available for renewal.");
}

const NotRenewable = ({ name, status, reason, closeDomain }) =>
  h(
    Box,
    {
      className: "not_renewable",
      onClose: closeDomain,
      title: name,
      props: { "data-status": status }
    },
    [
      h(BoxItem, [
        h(".label.flex-center", [
          fasIcon("exclamation-circle", {
            className: "status",
            style: { marginRight: "20px" }
          }),
          h(
            ".reason",
            { style: { paddingTop: "10px", paddingBottom: "10px" } },
            reason
          )
        ])
      ])
    ]
  );

NotRenewable.propTypes = {
  name: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  closeDomain: PropTypes.func.isRequired,
  status: PropTypes.string
};

class SearchForm extends Component {
  componentDidMount() {
    window.initRenewals(
      this.props.domains,
      $(this.refs.form).find("input"),
      "renew results"
    );
  }

  render() {
    return h(
      "form.search.domains#domains",
      { ref: "form", method: "post", action: "/renew/check_domains" },
      [
        h(SearchInput, {
          name: "domains",
          tabIndex: 1,
          placeholder: "Add another domain to renew"
        }),
        h("button.btn.search.primary", fasIcon("undo"))
      ]
    );
  }
}

const SearchInput = props =>
  h("input", {
    type: "text",
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "off",
    spellCheck: "false",
    ...props
  });

export default RenewResults;
