import { h, fasIcon } from "h";
import ReactModal from "react-modal";

function ClaimsPopup({ open, domains, onClose, onContinue }) {
  const aDomainIs =
    domains.length === 1
      ? "a domain in your shopping cart is"
      : `${domains.length} domains in your shopping cart are`;

  const style = {
    content: {
      width: "calc(50% + 60px)",
      border: "10px solid black",
      borderRadius: 0,
      padding: "20px",
      left: 0,
      right: 0,
      top: "40px",
      bottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      height: "auto"
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    }
  };

  return h(
    ReactModal,
    {
      style,
      isOpen: open,
      onRequestClose: onClose,
      className: "claims_popup",
      shouldCloseOnOverlayClick: false
    },
    [
      h("button.btn-link.modal-close", { onClick: onClose }, fasIcon("times")),
      h(".modal-content", [
        h("h2", "Trademark Notice"),
        h(
          "p",
          `Unfortunately, ${aDomainIs} subject to a trademark claim in the Trademark Clearinghouse database. During the first 90 days after the launch of any new top-level domain, we are required to notify potential registrants whenever there is an attempt to register a domain that is an exact match to an existing trademark.`
        ),
        h(
          "p",
          `We've removed the following trademarked ${
            domains.length === 1 ? "domain" : "domains"
          } from your cart:`
        ),
        h("ul.claims_domains_list", domains.map(name => h("li", name))),
        h(
          "p",
          "You may want to try another similar search that avoids using a trademarked word or brand-name. If you are the holder of the trademark and wish to purchase a domain that matches, please contact Hover Support for assistance."
        )
      ]),
      h(".modal-buttons", [
        h("button.btn.primary.continue", { onClick: onContinue }, "Continue")
      ])
    ]
  );
}

export default ClaimsPopup;
