import { Component } from "react";
import h from "h";

export default class PricingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      tldType: this.startCategory(this.props.start_category),
      discount: this.props.initial_discount,
      narrowCol: "new",
      narrow: false
    };
  }

  UNSAFE_componentWillMount() {
    if (typeof document !== "undefined") {
      const node = document.querySelector(".table");
      this.handleResize(node, null);
      window.addEventListener("resize", () => this.handleResize(node));
    }
  }

  handleResize(node) {
    this.setState({ isNarrow: node.offsetWidth < 640 });
  }

  startCategory(category) {
    if (this.props.filters[category]) {
      return category;
    } else {
      return "trending";
    }
  }

  renderFilters() {
    const discountOptions = this.props.discounts.map(discount =>
      h("option", { key: discount.name, value: discount.amount }, discount.name)
    );
    const updateFilterText = event =>
      this.setState({ filterText: event.target.value });
    const changeDiscount = event => this.setState({ discount: event.target.value });

    return h("form", [
      h(".filter", [
        h("i.fas.fa-search"),
        h("input", {
          type: "text",
          placeholder: "Search for a specific TLD",
          value: this.state.filterText,
          onChange: updateFilterText
        })
      ]),
      h("label.bulk", [
        h("span", "Show pricing for"),
        h(
          ".selectmenu",
          h(
            "select",
            { value: this.state.discount, onChange: changeDiscount },
            discountOptions
          )
        )
      ])
    ]);
  }

  narrowTable(rows) {
    return h("table", [
      h("thead", [
        h("thead", [
          h(
            "tr",
            h("th", { colSpan: 2 }, [
              this.tldTypeAction("Trending"),
              this.tldTypeAction("Newest"),
              this.tldTypeAction("On Sale"),
              this.tldTypeAction("All")
            ])
          ),
          h("th", { colSpan: 2 }, [
            this.eventTypeAction("new", "Registration"),
            this.eventTypeAction("renew", "Renewals"),
            this.eventTypeAction("transfer", "Transfers")
          ])
        ])
      ]),
      h("tbody", rows)
    ]);
  }

  wideTable(rows) {
    return h("table", [
      h("thead", [
        h("tr", [
          h("th", [
            this.tldTypeAction("Trending"),
            this.tldTypeAction("Newest"),
            this.tldTypeAction("On Sale"),
            this.tldTypeAction("All")
          ]),
          h("th", { className: this.hl("new") }, "Registration"),
          h("th", { className: this.hl("renew") }, "Renewals"),
          h("th", { className: this.hl("transfer") }, "Transfers")
        ])
      ]),
      h("tbody", rows)
    ]);
  }

  renderTable() {
    const prices = this.pricesToRender();
    const periodsToHypens = name => name.split(".").join("-");
    const rows = prices.map(price => {
      const linkOrName = price.lander
        ? h(
            "a",
            { href: `/tld/buy-${periodsToHypens(price.name)}-domain` },
            price.name
          )
        : `.${price.name}`;
      return h("tr", { key: price.name }, [
        h("td", linkOrName),
        h("td", { className: this.hl("new") }, this.currency(price, "new_price")),
        h("td", { className: this.hl("renew") }, this.currency(price, "renew")),
        h("td", { className: this.hl("transfer") }, this.currency(price, "transfer"))
      ]);
    });

    return this.state.isNarrow ? this.narrowTable(rows) : this.wideTable(rows);
  }

  hl(type) {
    if (this.state.isNarrow) {
      return this.state.narrowCol === type ? "selected" : "unselected";
    } else {
      return this.props.highlight === type ? "highlight" : null;
    }
  }

  pricesToRender() {
    // const filterByText = (name) => name.indexOf(this.filterSubstring()) >= 0;
    const filterByText = name => naiveFuzzySearch(this.filterSubstring(), name);
    const prices = this.props.prices;
    window.prices = prices;
    const tldNames =
      this.props.filters[this.tldType()] || Object.keys(prices).sort();
    const filteredTldNames = tldNames.filter(filterByText).sort((a, b) => {
      const splitNameA = a.split(".");
      const splitNameB = b.split(".");
      if (splitNameA[splitNameA.length - 1] < splitNameB[splitNameB.length - 1]) {
        return -1;
      }
      if (splitNameA[splitNameA.length - 1] > splitNameB[splitNameB.length - 1]) {
        return 1;
      }
      return 0;
    });
    return filteredTldNames.map(function(name) {
      return fromNetwork(name, prices[name]);
    });

    function fromNetwork(name, row) {
      let tldData = {
        name: name,
        lander: row[0],
        new_price: row[1],
        renew: row[2],
        transfer: row[3]
      };
      if (row[4]) {
        tldData.regular = {
          new_price: row[4],
          renew: row[5],
          transfer: row[6]
        };
      }

      return tldData;
    }
  }

  filterSubstring() {
    return this.state.filterText.replace(/[^a-z]/gi, "").toLowerCase();
  }

  tldType() {
    if (this.filterSubstring() !== "") {
      return "all";
    }
    return this.state.tldType;
  }

  tldTypeAction(title) {
    const name = titleToName(title.toLowerCase());

    const cls = this.tldType() === name ? "active" : null;
    const onClick = () => this.setState({ tldType: name });

    return h("a", { href: "javascript:void(0)", onClick, className: cls }, title);

    function titleToName(title) {
      if (title === "on sale") {
        return "sale";
      } else {
        return title.replace(/ /g, "_");
      }
    }
  }

  eventTypeAction(name, title) {
    const cls = this.state.narrowCol === name ? "active" : null;
    const onClick = () => this.setState({ narrowCol: name });
    return h("a", { href: "javascript:void(0)", onClick, className: cls }, title);
  }

  currency(price, type) {
    const discount = this.state.discount;
    const amount = price[type];
    var regular;

    if (price.regular && (regular = price.regular[type]) && regular !== amount) {
      return h("span.price.discounted", [
        h("span.now", fmt(amount)),
        h("span", " "),
        h("span.reg", fmt(regular))
      ]);
    } else {
      return fmt(amount);
    }

    function fmt(amount) {
      if (type === "renew" && discount) amount -= discount;
      const price = "$" + parseFloat(amount).toFixed(2);
      return h("span.price", price);
    }
  }

  render() {
    return h("div", [this.renderFilters(), this.renderTable()]);
  }
}

function naiveFuzzySearch(searchTerm, element) {
  if (searchTerm === "") {
    return true;
  }
  var tLen = searchTerm.length;
  var eLen = element.length;

  if (tLen > eLen) {
    return false;
  }

  if (tLen === eLen) {
    return searchTerm === element;
  }

  outer: for (var i = 0, j = 0; i < tLen; i++) {
    var nch = searchTerm.charCodeAt(i);
    while (j < eLen) {
      if (element.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }

  return true;
}
