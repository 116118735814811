import { TransitionGroup, CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { h, fasIcon } from "h";
import { priceSpans } from "checkout/lookup/price";

const MiniCart = (header, itemList, summary) => {
  return h(".minicart", [
    h(".header", header),
    h(".cart", [h("ul", itemList), h(".summary", summary)])
  ]);
};

export const CheckoutMiniCart = ({ items, summaryItems, subtotal, total }) => {
  const renderCheckoutCartItem = item =>
    h("li.cart-item.minicart-item", { key: item.name }, [
      renderItemName(item),
      renderSubItems(item)
    ]);

  const renderCheckoutSummary = (subtotal, summaryItems, total) =>
    h(".summary", [
      h(".summary_items", [
        renderCartSubtotal(subtotal),
        ...summaryItems.map(renderCartSummaryItem)
      ]),
      renderCartTotal(total)
    ]);

  const header = [
    h("h2", "Cart Summary"),
    h("a.btn-link.btn-edit", { href: "/cart" }, "Edit")
  ];

  const itemList = items.map(renderCheckoutCartItem);

  const summary = renderCheckoutSummary(subtotal, summaryItems, total);

  return MiniCart(header, itemList, summary);
};

CheckoutMiniCart.propTypes = {
  ...cartProps,
  total: PropTypes.string.isRequired,
  summaryItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

export const LookupMiniCart = ({ items, subtotal, onRemoveFromCart, onEmptyCart, note }) => {
  if (!items || items.length === 0) {
    return null;
  }

  const renderLookupSummary = subtotal =>
    h(".summary", [
      h(".summary_items", [
        h(".cart_total", [h(".label", "Subtotal"), h(".amount", "USD " + subtotal)]),
        h(".buttons", [h("a.btn.primary", { href: "/cart" }, "Proceed to Cart")])
      ])
    ]);

  const renderLookupCartItem = onRemoveFromCart => item => {
    return h("li.cart-item", { key: item.name }, [
      renderItemName(item),
      h(
        "button.remove_cart",
        {
          title: "Remove from cart",
          onClick: () => onRemoveFromCart(item.name)
        },
        fasIcon("times")
      ),
      renderSubItems(item)
    ]);
  };

  function renderNote(note) {
    return fadeSlide(note ? h(".note", note) : []);
  }

  const header = [
    renderNote(note),
    h("h2", "Cart Summary"),
    h("button.btn-link.btn-edit", { onClick: onEmptyCart }, "Clear Cart")
  ];

  const itemList = fadeSlideList(items.map(renderLookupCartItem(onRemoveFromCart)));

  const summary = renderLookupSummary(subtotal);

  return MiniCart(header, itemList, summary);
};

LookupMiniCart.propTypes = {
  ...cartProps,
  note: PropTypes.string,
  onRemoveFromCart: PropTypes.func.isRequired
};

function renderItemName(item) {
  const star = item.type === "premium" && premiumStar(item.premium_type);
  const bundle = !!item.bundle && h("span.bundle_offer_label", ["Bundle offer"]);

  return h("span.name", [item.name, star, bundle]);
}

function premiumStar(premiumType) {
  return fasIcon("star", {
    className: `premium-star premium-${premiumType}`
  });
}

function fadeSlide(items) {
  const transition = {
    transitionName: "note",
    timeout: {
      enter: 500,
      exit: 500
    }
  }
  return h(
    TransitionGroup,
    mapItems(items, transition)
  );
}

function fadeSlideList(items) {
  const transition = {
    classNames: "cart-item",
    timeout: {
      enter: 500,
      exit: 500
    }
  }
  return h(
    TransitionGroup,
    {
      component: "ul"
    },
    mapItems(items, transition)
  );
}

function mapItems(items, transition) {
  return items.map(item => h(CSSTransition, {
    ...transition
  }, item))
}

function renderSubItems(item) {
  if (item.components.length > 0) {
    return h(
      ".sub",
      item
        .components
        .map((component, idx) => renderItem(component, idx, item))
    );
  }
}

const renderItem = (componentData, componentIndex, item) => {
  let amount = priceSpans(componentData);

  if (item.bundle && componentData.amount) {
    amount = componentData.amount;
  }

  return h(".row", { key: componentIndex }, [
    h(".label", componentData.label),
    "\xa0", // for cukes
    h(".amount", amount)
  ]);
}

function renderCartSubtotal(total) {
  return h(".cart_summary_item", { fontWeight: 400 }, [
    h(".label", "Subtotal"),
    h(".amount", total)
  ]);
}

function renderCartSummaryItem({ label, amount }) {
  return h(".cart_summary_item", [h(".label", label), h(".amount", amount)]);
}

function renderCartTotal(total) {
  return h(".cart_total.is_last", [
    h(".label", "Total"),
    " ", // for cukes
    h(".amount", `USD ${total}`)
  ]);
}

const cartProps = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtotal: PropTypes.string.isRequired
};
