import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Input from "./input";
import ErrorContainer from "../form/ErrorContainer";
import styles from "./cart_totals.module.sass";

function PromoCodeForm(props) {
  if (!props.cart.canEnterPromoCode) {
    return null;
  }
  const { serverError } = props.cart;
  const [state, setState] = useState({
    isOpen: false || !!serverError,
    dirty: false,
    promoCode: "",
  });
  const openForm = () => setState({ isOpen: true, dirty: false, promoCode: "" });
  const onSubmit = (e) => {
    e.preventDefault();
    if (state.promoCode === "") return;
    props.actions.applyPromoCode(state.promoCode);
  };

  const { isOpen, dirty, promoCode } = state;
  const confirmed = !dirty && !serverError && promoCode != "";
  if (isOpen) {
    const error = dirty && serverError ? serverError : null;
    const inputProps = {
      id: "promo_code_input",
      type: "text",
      value: promoCode,
      onChange: (e) => {
        setState({
          ...state,
          promoCode: e.target.value,
          dirty: true
        });
        props.actions.changeServerError(null)
      },
      onBlur: () => setState({ ...state, dirty: true }),
      autoFocus: true,
      confirmed
    };
    const field = {
      error: serverError,
      isReady: confirmed
    }
    const buttonClass = c("btn", { primary: dirty && !!promoCode });
    return (
      <div className={styles.promo_code}>
        <form className="valign validate" onSubmit={onSubmit}>
          <ErrorContainer field={field} showReady={false} innerClass={styles.error_container}>
            <Input {...inputProps}>
            </Input>
            <button className={buttonClass} disabled={serverError}>Apply</button>
          </ErrorContainer>
        </form>
      </div>
    );
  } else {
    return (
      <div className={styles.promo_code}>
        <button className="btn-link" onClick={openForm}>
          Add Promo Code
        </button>
      </div>
    );
  }
}

PromoCodeForm.propTypes = {
  cart: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default PromoCodeForm;
