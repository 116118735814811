import PropTypes from "prop-types";
import { h } from "h";
import {
  isIndividualType,
  isIndividualName,
  isOrganizationType,
  isOrganizationName
} from "../util/cira";

export default function CiraLegalTypeWarning({ registrant_name, legal_type }) {
  if (isIndividualType(legal_type) && !isIndividualName(registrant_name)) {
    const markup = document.getElementById("ca_forbidden").innerHTML;
    return h(".legal-type-warning.individual", {
      dangerouslySetInnerHTML: { __html: markup }
    });
  }

  if (isOrganizationType(legal_type) && !isOrganizationName(registrant_name)) {
    const markup = document.getElementById("ca_required").innerHTML;
    return h(".legal-type-warning.individual", {
      dangerouslySetInnerHTML: { __html: markup }
    });
  }

  return h("div");
}

CiraLegalTypeWarning.propTypes = {
  registrant_name: PropTypes.string,
  legal_type: PropTypes.string
};
