import { Component } from "react";
import { h } from "h";
import TwoFactorRecovery from "../TwoFactorRecovery";

export default class TwoFactorRecoveryForm extends Component {
  render() {
    const { loading, mobile, note, error, actions } = this.props;
    const signin = { note, error, mobile, loading };

    return h(TwoFactorRecovery, { signin, actions });
  }
}
