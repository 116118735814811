import PropTypes from "prop-types";
import React from "react";
import c from "classnames";
import h, { fasIcon } from "h";

export function Box({ title, onClose, subHeading, className, props, children }) {
  const heading = h("h2", title);
  const closeButton =
    onClose && h("button.close", { onClick: onClose }, fasIcon("times"));
  const boxProps = { className: c("box", className), ...props };
  const ulClass = c("", { "push-right": subHeading });
  // const className = c("", );
  return h("div", boxProps, [
    closeButton,
    heading,
    subHeading && subHeading,
    h("ul", { className: ulClass }, children)
  ]);
}

Box.propTypes = {
  title: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  subHeading: PropTypes.element
};

export function BoxItem({ className, children }) {
  return h("li", { className }, h(".boxitem-ie", h(".boxitem", children)));
}
