import { Component } from "react";
import { connect } from "react-redux";
import { CheckoutMiniCart } from "../components/MiniCart";
import h from "h";
import { div, h1 } from "tags";
import { AsideHelp } from "elements/aside_help";
import { isSignedIn } from "../reducers";
import updateSigninMenu from "../util/updateSigninMenu";

class CheckoutMain extends Component {
  componentDidUpdate(oldProps) {
    if (oldProps.signedIn !== this.props.signedIn) {
      updateSigninMenu();
    }
  }

  render() {
    const { checkout, dispatch } = this.props;
    const cart = this.props.cart;
    const supportOpen = checkout.supportOpen;

    return div(".results", [
      h1("Checkout"),
      div(".left", this.props.children),
      div(".right", [
        h(CheckoutMiniCart, cart),
        h(AsideHelp, { supportOpen, dispatch })
      ])
    ]);
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout.asMutable({ deep: true }),
    cart: state.cart.asMutable({ deep: true }),
    signedIn: isSignedIn(state)
  };
}

export default connect(mapStateToProps)(CheckoutMain);
