export const startLoading = () => ({ type: "START_LOADING" });
export const stopLoading = () => ({ type: "STOP_LOADING" });
export const confirmNavigation = step => ({
  type: "CONFIRM_NAVIGATION",
  step
});
export const cancelNavigation = () => ({ type: "CANCEL_NAVIGATION" });
export const goto = (step, errors) => ({ type: "NAVIGATE", step, errors });
export const notifyEnteredStep = step => ({
  type: "NOTIFY_ENTERED_STEP",
  step
});
export const notifyUnsaved = isUnsaved => ({
  type: "NOTIFY_UNSAVED",
  isUnsaved
});
export const redirectToNextStep = () => ({ type: "REDIRECT_TO_NEXT_STEP" });
export const submitRegistrationData = data => ({
  type: "SUBMIT_REGISTRATION_DATA",
  remote: true,
  payload: data
});
export const submitTldData = data => ({
  type: "SUBMIT_TLD_DATA",
  remote: true,
  payload: data
});
export const submitMailboxData = data => ({
  type: "SUBMIT_MAILBOX_DATA",
  remote: true,
  payload: data
});
export const submitBillingData = data => ({
  type: "SUBMIT_BILLING_DATA",
  remote: true,
  payload: data
});
export const submitOrder = data => ({
  type: "SUBMIT_ORDER",
  remote: true,
  payload: data
});
export const selectAccountMode = formType => ({
  type: "SELECT_ACCOUNT_MODE",
  formType
});
export const successfulSignin = data => ({
  type: "SUCCESSFUL_SIGNIN",
  remote: true,
  data
});
export const optInNewsletter = data => ({
  type: "OPT_IN_NEWSLETTER",
  remote: true,
  payload: data
});
export const refreshCheckoutState = data => ({
  type: "REFRESH_CHECKOUT_STATE",
  payload: data
});
export const enteredStep = step => ({
  type: "ENTERED_STEP",
  payload: step
});
export const createAccount = data => ({
  type: "CREATE_ACCOUNT",
  remote: true,
  payload: data
});
export const agreeToTerms = () => ({
  type: "AGREE_TO_TERMS",
  remote: true
});
export const startGlobalSignin = () => ({ type: "GLOBAL_SIGNIN" });
