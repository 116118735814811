import PropTypes from "prop-types";
import { h } from "h";
import Crossfade from "./Crossfade";
import { div } from "tags";

function SectionContent({ stepIsActive, stepIsCompleted, viewer, editor, title }) {
  const v = stepIsCompleted && viewer ? div(".viewer", viewer()) : null;
  const e = stepIsActive && editor ? div(".editor", editor()) : null;

  return h(Crossfade, { child2: v, child1: e, title: title });
}

SectionContent.propTypes = {
  stepIsActive: PropTypes.bool.isRequired,
  stepIsCompleted: PropTypes.bool.isRequired,
  viewer: PropTypes.func.isRequired,
  editor: PropTypes.func.isRequired
};

export default SectionContent;
