import ReactDOM from "react-dom";
import Immutable from "immutable";

import includes from "lodash/includes";
import groupBy from "lodash/groupBy";
import merge from "lodash/merge";

import { h, parseDollarAmount, gaTrackEvent, updateHeaderCartItemCount } from "h";
import { xhrPost } from "common/xhr";

import Signal from "./signal";
import { initCart } from "checkout/reducers/initCart";

export default function initDomainLookup(root, data) {
  $(() => {
    let signal = Signal();
    let model = initLookupModel(data);

    signal.on("requestMoreResults", requestMoreResults, signal.endChain);
    signal.on("updateLookupResults", updateLookupResults);
    signal.on("addToCart", addToCart);
    signal.on("removeFromCart", removeFromCart, signal.endChain);
    signal.on("emptyCart", emptyCart, signal.endChain);
    signal.on("noMoreData", noMoreData);
    signal.on("offerLearnMore", offerLearnMore, signal.endChain);
    signal.on("offerSedo", offerSedo, signal.endChain);
    signal.on("cartUpdated", updateCartItemCount);
    signal.on("toggleCategory", logToggleCategory);

    signal.foldp(update, model, function(newModel) {
      model = newModel;
      renderApp();
    });

    if (module.hot) {
      module.hot.accept("./lookup_view", () => {
        renderApp();
      });
    }

    signal.sendAction({
      name: "requestMoreResults",
      isVoid: true
    });

    function track(action, label, value) {
      gaTrackEvent("DomainLookup", action, label, value);
    }

    function renderApp() {
      const DomainLookup = require("./lookup_view").default;
      const props = { model: forRendering(model, signal) };
      return ReactDOM.render(h(DomainLookup, props), root);
    }

    function requestMoreResults(action, model) {
      if (model.get("attempts") <= 12) {
        $.getJSON("/api/lookup", data.qparams, result => {
          signal.sendAction({
            name: "updateLookupResults",
            data: result
          });
        }).fail(() => {
          setTimeout(() => signal.sendAction({ name: "requestMoreResults" }), 1000);
        });
        signal.sendAction({ name: "askedForResults" });
      } else {
        signal.sendAction({ name: "noMoreData" });
      }
    }

    async function addToCart(action, model) {
      signal.sendAction({ name: "startLoading", items: action.items });
      for (const el of action.items) {
        const name = el;
        const { categoryName } = action;
        const search_key = model.getIn(["qparams", "return_key"]);
        await xhrPost("/cart/add_to_cart", { name, search_key })
          .then(result => signal.sendAction({ name: "cartUpdated", cart: result }))
          .finally(() => signal.sendAction({ name: "stopLoading", items: [name] }));
        track("add_to_cart", name);
        track("add_to_cart_from_category", categoryName);
      }
    }

    function removeFromCart(action, model) {
      const name = action.items[0];
      const categoryName = action.categoryName;
      signal.sendAction({ name: "startLoading", items: [name] });
      $.post("/cart/remove_cart", { name }, result => {
        signal.sendAction({ name: "cartUpdated", cart: result });
        signal.sendAction({ name: "stopLoading", items: [name] });
      });
      track("remove_from_cart", name);
      track("remove_from_cart_from_category", categoryName);
    }

    function emptyCart(_action) {
      $.post("/cart/empty_cart", cart =>
        signal.sendAction({ name: "cartUpdated", cart })
      );
      track("empty_cart", "emptyCart");
    }

    function noMoreData(_action, model) {
      setTimeout(() => signal.sendAction({ name: "removeProgressBar" }), 3000);

      if (model.getIn(["progress", "count"], 0) === 0) {
        const qparams = model.get("qparams").toJS();
        $.post("/lookup/dump_log", qparams);
      }
    }

    function updateLookupResults(action) {
      if (action.data.complete) {
        setTimeout(() => signal.sendAction({ name: "noMoreData" }), 100);
      } else {
        setTimeout(() => signal.sendAction({ name: "requestMoreResults" }), 1000);
      }
    }

    function offerLearnMore(action) {
      setTimeout(() => {
        track("click_domainagents_modal", action.domainName);
        document.location.href = `http://domainagents.com/affiliate.php?domain=${
          action.domainName
        }&code=hover`;
      }, 100);
    }

    function offerSedo(action) {
      if (action.sedoPartnerUrl) {
        const url = action.sedoPartnerUrl.replace("{domain}", action.domainName);
        setTimeout(() => {
          track("click_sedo_modal", action.domainName);
          document.location.href = url;
        }, 100);
      }
    }

    function updateCartItemCount(action) {
      updateHeaderCartItemCount(action.cart.cart.length);
    }

    function logToggleCategory(action, model) {
      const id = action.id;
      const category = model.get("categories").find(c => c.get("id") == id);
      const categoryName = category ? category.get("title") : String(id);

      if (action.checked) {
        track("toggle_category_on", categoryName);
      } else {
        track("toggle_category_off", categoryName);
      }
    }
  });
}

const filterAll = Immutable.Set(["all"]);

function update(action, model) {
  switch (action.name) {
    case "startLoading":
      return model.set("loading", buildLoadingObject(model, action.items));
    case "stopLoading":
      return model.set("loading", buildLoadingObject(model, action.items, false));
    case "askedForResults":
      return model.update("attempts", n => n + 1);
    case "updateLookupResults":
      return updateResults(action.data, model);
    case "cartUpdated":
      return updateCart(action.cart, model);
    case "noMoreData":
      return finalizeLookup(model);
    case "toggleCategory":
      return model.update("filters", toggleCategory(action.id));
    case "removeProgressBar":
      return model.setIn(["progress", "hidden"], true);
    case "openOfferDialog":
      return model.set("offerDialog", true).set("offerDomain", action.domain);
    case "closeOfferDialog":
      return model
        .set("offerDialog", false)
        .set("sedoDialog", false)
        .set("offerDoman", null);
    case "openSedoDialog":
      return model.set("sedoDialog", true).set("offerDomain", action.domain);

    default:
      return model;
  }

  function updateCart(cart, model) {
    return model.withMutations(m => {
      m.set("cart", Immutable.fromJS(initCart(cart)));
    });
  }

  function updateResults(result, model) {
    result = mergeResults(result, model);
    const groupedResults = Immutable.fromJS(groupResults(result));
    const premiumKnown =
      model.getIn(["progress", "premiumKnown"]) || result.has_premium;
    const exactMatchTaken =
      !result.keywords &&
      ((premiumKnown || result.complete) && result.exact_known) &&
      !result.exact;
    const progress = Immutable.fromJS({
      count: result.count,
      max: result.estimated_max_results,
      premiumKnown: premiumKnown,
      exactMatchTaken: exactMatchTaken,
      exactMatchBlocked: result.exact_blocked,
      registryPresumedDown: isRegistryDown(),
      domainAgents: result.domain_agents,
      makeOffers: result.make_offers,
      query: result.query
    });

    return model
      .set("groupedResults", groupedResults)
      .set("error", result.error)
      .mergeIn(["progress"], progress);

    function isRegistryDown() {
      if (lookupInProgress) return false;

      const lookupInProgress = model.get("lookupInProgress", false);
      const exact = model.getIn(["qparams", "exact_search"]);
      return includes(result.undertermined, exact);
    }
  }

  function finalizeLookup(model) {
    if (model.getIn(["progress", "count"], 0) === 0) {
      const error = "Sorry! There was a problem finding suggestions for you.";
      model = model.set("error", error);
    }
    return model.set("lookupInProgress", false);
  }

  function toggleCategory(id) {
    return function(filterSet) {
      if (id === "all") {
        // Turning on "all" clears all other checkboxes.
        // Turning off "all" has no effect.
        return filterAll;
      } else if (filterSet.includes(id) && filterSet.count() === 1) {
        // Turning off last checked box turns on "all"
        return filterAll;
      } else if (!filterSet.includes(id)) {
        return filterSet.add(id).delete("all");
      } else {
        return filterSet.delete(id);
      }
    };
  }
}

export function buildLoadingObject(model, items, isLoading = true) {
  return items.reduce(
    (acc, i) => ({ ...acc, [i]: isLoading }),
    model.toJS().loading
  );
}

function forRendering(model, signal) {
  return addMissingExactMatch(
    markPriceLimit(markInCart(model).set("sendAction", signal.sendAction))
  );

  function markInCart(model) {
    const cart = model.get("cart");
    const itemsInCart = cart
      .get("items")
      .map(item => item.get("name"))
      .toSet();

    return model.update("groupedResults", groupedResults => {
      return groupedResults.map(cat => {
        return cat.map(item => {
          if (itemsInCart.includes(item.get("domain"))) {
            return item.set("inCart", true);
          } else {
            return item;
          }
        });
      });
    });
  }

  function markPriceLimit(model) {
    const priceLimit = model.get("priceLimit");
    if (!priceLimit) {
      return model;
    }
    return model.update("groupedResults", groupedResults => {
      return groupedResults.map(cat => {
        return cat.map(item => {
          const newPrice = item.get("price");
          const amount = parseDollarAmount(newPrice);
          if (amount > priceLimit) {
            return item.set("expensive", true);
          } else {
            return item;
          }
        });
      });
    });
  }

  function addMissingExactMatch(model) {
    if (shouldAddMissingExact(model)) {
      return model.update("groupedResults", groupedResults => {
        return groupedResults.set("exact", exactMatchHit(model));
      });
    } else {
      return model;
    }
  }

  function exactMatchHit(model) {
    return Immutable.fromJS([
      {
        domain: model.getIn(["progress", "query"]),
        status: "taken"
      }
    ]);
  }
}

export function shouldAddMissingExact(model) {
  if (model.get("results").size == 0) return false;
  const exactMatchBlocked = model.getIn(["progress", "exactMatchBlocked"]);
  const exactMatchTaken = model.getIn(["progress", "exactMatchTaken"]);
  return exactMatchTaken && !exactMatchBlocked;
}

function initLookupModel(data) {
  return Immutable.fromJS({
    results: data.results,
    groupedResults: groupResults(data.results),
    categories: initCategories(data.categories),
    integration: data.integration,
    numRegistries: data.numRegistries,
    cart: Immutable.fromJS(initCart(data.cart)),
    qparams: data.qparams,
    lookupInProgress: true,
    progress: {
      count: data.results.results.length,
      max: data.results.estimated_max_results
    },
    loading: {},
    filters: Immutable.Set(["all"]),
    modalDialogHtml: $(".domainagents").html(),
    offerDialog: false,
    sedoDialog: false,
    sedoPartnerUrl: data.sedo_partner_url,
    priceLimit: data.price_limit,
    attempts: 0
  });

  function initCategories(categories) {
    let list = categories.map(cat => ({
      id: cat[0].toString(),
      title: cat[1]
    }));
    return Immutable.fromJS(list);
  }
}

function mergeResults(result, model) {
  if (!model.get("groupedResults")) {
    return result;
  }
  const incomingDomainNames = new Set(result.results.map(r => r.domain));
  const currentHits = [].concat(
    ...Object.values(model.get("groupedResults").toJS())
  );
  const toKeep = currentHits.filter(r => !incomingDomainNames.has(r.domain));
  const mergedResults = Immutable.fromJS(toKeep).concat(result.results);
  return Immutable.fromJS(result)
    .set("results", mergedResults)
    .set("count", mergedResults.count())
    .toJS();
}

function groupResults(result) {
  const allHits = recategorizeHits(result)
    .sort(byRank)
    .sort(byFree);
  return groupBy(allHits, hit => hit.get("categoryId"));
}

function byRank(a, b) {
  const arank = a.get("categoryRank");
  const brank = b.get("categoryRank");
  return arank - brank;
}

function byFree(a, b) {
  if (isFree(a.get("price")) && !isFree(b.get("price"))) {
    return -1;
  } else if (isFree(!a.get("price")) && isFree(b.get("price"))) {
    return 1;
  } else {
    return 0;
  }
}

function isFree(val) {
  return val === "$0.00" || val === "$0" ? true : false;
}

function recategorizeHits(result) {
  const results = result.results;
  const keywords = handleKeyWords(result.keywords);
  const hasExact = hasExactHit(results);
  const hasComKey = hasComKeyHit(keywords, results);
  const query = result.query;
  const bundleTlds = result.bundle_tlds;
  return results.map(hit =>
    Immutable.Map(hit).merge(recategorize(hit, hasExact, hasComKey))
  );

  function recategorize(hit, hasExact, hasComKey) {
    const rank = hit.search_category_rank;
    const type = hit.result_type;
    const name = hit.domain;
    const isComKey = (keywords, name) =>
      keywords.join("") === name.replace(".com", "");
    const isTldTail = (keywords, name) =>
      keywords.join("") === name.replace(".", "");
    if (hit.is_exact) {
      return { categoryId: "exact", categoryRank: rank };
    } else if (!hasExact && isComKey(keywords, name)) {
      return { categoryId: "exact", categoryRank: 0 };
    } else if (!hasExact && !hasComKey && isTldTail(keywords, name)) {
      return { categoryId: "exact", categoryRank: 0 };
    } else if (canBundle(bundleTlds, keywords, name, query, type)) {
      return { categoryId: "top", categoryRank: 0 };
    } else if (type === "best_match" || type === "top_pick") {
      return { categoryId: "top", categoryRank: rank };
    } else if (hit.partner_promotion && type !== "supplementary") {
      return { categoryId: "top", categoryRank: hit.partner_position };
    } else {
      return { categoryId: hit.search_category_id, categoryRank: rank };
    }
  }
}

function canBundle(bundleTlds, keywords, name, query, type) {
  if (!isSuitableTopType(type)) {
    return false;
  } else
    return (
      bundleTlds.some(tld => name.endsWith(tld)) &&
      isSuitableTopType(type) &&
      isSuitableDomain(keywords, name, query)
    );
}

function isSuitableDomain(keywords, name, query) {
  const sld = name.split(".")[0];
  if (keywords.length > 0) {
    return keywords.includes(sld) || keywords.join("") === sld;
  } else {
    return query.split(".")[0] === sld;
  }
}

function isSuitableTopType(type) {
  return type !== "supplementary" && type !== "premium_make_offer";
}

function handleKeyWords(keywords) {
  if (!keywords) {
    return [];
  } else {
    return Array.isArray(keywords) ? keywords : keywords.split(" ");
  }
}

function hasExactHit(results) {
  return !!results.find(hit => hit.is_exact);
}

function hasComKeyHit(keywords, results) {
  if (keywords.length === 0) {
    return false;
  }
  return !!results.find(hit => keywords.includes(hit.domain.replace(".com", "")));
}
