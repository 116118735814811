import PropTypes from "prop-types";
import React from "react";
import c from "classnames";
import h from "h";

import { BoxItem } from "../../elements/box";
import Input from "./input";

function AuthCode({ item, strict, actions }) {
  const registrar = item.components[0].losing_registrar;
  //const help = item.components[0].auth_code_help;
  const authCode = item.components[0].auth_code || "";
  const notice = h(".notice", [
    h("em", "Authorization Code"),
    `: In order for us to transfer your domain to Hover from ${registrar}, `,
    `you will need to enter your authorization code assigned to your domain by ${registrar}.`
    // linkToTargetBlank("Need help?", help)
  ]);

  return h(BoxItem, { className: "auth_code" }, [
    h(".auth_code", [notice, h(AuthCodeForm, { item, authCode, strict, actions })])
  ]);
}

class AuthCodeForm extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    authCode: PropTypes.string,
    strict: PropTypes.bool.isRequired
  };

  state = {
    authCode: this.props.authCode,
    dirty: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.authCode !== nextProps.authCode) {
      this.setState({ dirty: false, authCode: nextProps.authCode });
    }
  }

  render() {
    return h(
      "form.valign.validate.auth_code",
      { onSubmit: this.onSubmit },
      this.items()
    );
  }

  items = () => {
    const error = this.error();
    const confirmed = this.confirmed();
    const props = {
      className: c({ confirmed }),
      type: "text",
      placeholder: "Enter auth code",
      value: this.state.authCode,
      onChange: e => this.setState({ authCode: e.target.value, dirty: true }),
      onBlur: () => this.setState({ dirty: true }),
      errorMessage: error,
      confirmed: confirmed
    };

    return h(Input, props, [
      !confirmed &&
        !error &&
        h("button.btn", { className: this.state.dirty ? "primary" : "" }, "Submit")
    ]);
  };

  confirmed = () => {
    return this.state.authCode == this.props.authCode && !!this.state.authCode;
  };

  error = () => {
    if ((this.state.dirty || this.props.strict) && !this.state.authCode) {
      return "Auth code is required";
    } else {
      return null;
    }
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ dirty: true }, () => {
      if (!this.error()) {
        this.props.actions.changeAuthCode(this.props.item, this.state.authCode);
      }
    });
  };
}

export default AuthCode;
