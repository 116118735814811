import PropTypes from "prop-types";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import h from "h";
import { div } from "tags";
import get from "lodash/get";

import { isStepEditable } from "../store/invalidStep";

import * as checkoutActions from "../actions/checkout_actions";
import AccountStep from "./AccountStep";
import RegistrationStep from "./RegistrationStep";
import BillingStep from "./BillingStep";
import ConfirmStep from "./ConfirmStep";
import TldStep from "./TldStep";
import MailboxStep from "./MailboxStep";
import ConfirmNavigation from "../components/ConfirmNavigation";

class CheckoutAccordion extends Component {
  componentDidMount() {
    this.props.enteredStep(this.props.params.step);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.step !== this.props.params.step) {
      this.props.enteredStep(this.props.params.step);
    }
  }

  handleEdit(stepName) {
    if (this.props.checkout.unsaved) {
      return this.props.confirmNavigation(stepName);
    } else {
      return this.props.goto(stepName);
    }
  }

  render() {
    const { steps, formData, checkout, params: { step: currentStep } } = this.props;
    const confirmNavigation = checkout.confirmNavigation;

    const items = steps.map(step => {
      const stepIsActive = step.name === currentStep;
      const reactClass = getClassForStep(step.type);
      const onEdit = () => this.handleEdit(step.name);
      const formError = stepIsActive && get(this.props, "location.state._error");
      return h(reactClass, {
        key: step.name,
        stepName: step.name,
        stepData: step,
        [step.name]: formData[step.name] || {},
        stepIsActive,
        stepIsEditable: this.props.editable(step.name),
        formError,
        onEdit
      });
    });

    return div(".accordion", [
      ...items,
      h(ConfirmNavigation, {
        isOpen: !!confirmNavigation,
        onConfirm: () => this.props.goto(confirmNavigation),
        onCancel: () => this.props.cancelNavigation()
      })
    ]);
  }
}

CheckoutAccordion.propTypes = {
  steps: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  enteredStep: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired
};

const stepClasses = {
  account: AccountStep,
  registration: RegistrationStep,
  billing: BillingStep,
  tld: TldStep,
  mailbox: MailboxStep,
  confirm: ConfirmStep
};

function getClassForStep(type) {
  if (stepClasses[type]) {
    return stepClasses[type];
  } else {
    throw new Error(`No step handler class for ${type}`);
  }
}

function mapStateToProps(state) {
  const checkout = state.checkout.asMutable({ deep: true });
  const formData = checkout.model.formData;
  const steps = checkout.model.steps;
  const editable = isStepEditable(steps);
  return {
    checkout,
    steps,
    formData,
    editable
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(checkoutActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAccordion);
