import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";

import h from "h";
import { button } from "tags";
import * as checkoutActions from "../actions/checkout_actions";
import * as signinActions from "../actions/signinActions";

import Section from "../components/Section";
import AccountSkipped from "../components/AccountSkipped";
import AccountInfo from "../components/AccountInfo";
import AccountForm from "../components/AccountForm";
import StandAloneTosForm from "../components/StandAloneTosForm";
import Crossfade from "../components/Crossfade";
import { numSigninFailures } from "../reducers";

class AccountStep extends Component {
  render() {
    const { account, stepIsActive, accountForm } = this.props;
    const viewer = () =>
      account.skipped ? h(AccountSkipped) : h(AccountInfo, account);
    const editor = () => {
      const { accountForm, tosForm } = renderForm(this.props);
      return h(Crossfade, {
        child1: accountForm,
        child2: tosForm
      });
    };
    const editButton = renderEditButton(this.props);

    return h(Section, {
      ...this.props,
      title: "Your Account",
      viewer,
      editor,
      editButton,
      forceEdit: stepIsActive
    });
  }
}

function renderForm(props) {
  const { handleSubmit, canSkip, accountForm } = props;
  if (
    get(props, "account.mode") === "tos" &&
    accountForm.formName !== "signin"
  ) {
    return {
      tosForm: h(StandAloneTosForm, { handleSubmit: props.actions.agreeToTerms })
    };
  } else {
    return {
      accountForm: h(AccountForm, {
        ...props,
        onSubmit: handleSubmit,
        canSkip
      })
    };
  }
}

function renderEditButton({
  actions,
  onEdit,
  account,
  accountForm: { formName },
  stepIsActive
}) {
  const { unSkipSignin } = actions;
  if (stepIsActive) {
    const onClick = () => actions.toggleAccountForm();
    if (get(account, "mode") === "tos" && formName !== "signin") {
      return button(".btn-link.btn-edit", { onClick }, "Use a different account");
    } else {
      return button(
        ".btn-link.btn-edit",
        { onClick },
        formName === "create" ? "Use an existing account" : "Create an account"
      );
    }
  } else if (account.skipped) {
    return button(".btn-link.btn-edit", { onClick: unSkipSignin }, "Sign in");
  } else {
    return button(
      ".btn-link.btn-edit",
      { onClick: onEdit },
      "Use a different account"
    );
  }
}

function mapStateToProps(state) {
  const { checkout, signin, accountForm } = state;
  const initialValues = checkout.model.formData.account.asMutable();
  const canSkip = !!initialValues.can_skip;
  if (canSkip) {
    initialValues.mode = "signin";
  }

  return {
    account: initialValues,
    signin,
    accountForm,
    initialValues,
    canSkip,
    numSigninFailures: numSigninFailures(state),
    recaptchaKey: checkout.recaptchaKey,
    recaptchaSigninEnabled: checkout.recaptchaSigninEnabled,
    recaptchaSignupEnabled: checkout.recaptchaSignupEnabled,
    verificationRequired: checkout.verificationRequired,
    magicSigninEnabled: checkout.magicSigninEnabled
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { ...signinActions, ...checkoutActions };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountStep);
