import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import c from "classnames";
import { div } from "tags";
import validator from "../../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  CreatePasswordField,
  fieldType
} from "../../form";

class NewPasswordForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      password: fieldType.isRequired,
      showPassword: fieldType.isRequired
    }).isRequired,
    token: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    note: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  render() {
    const { form, fields, actions } = this.props;
    const { password, showPassword } = fields;

    const className = c(this.state.loading && "loading");
    const onSubmit = values => actions.setNewPassword(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: this.state.error }),
        div(".row", [
          h(CreatePasswordField, {
            width: 12,
            field: password,
            show: showPassword,
            autoFocus: true,
            label: "New Password"
          })
        ])
      ]),
      div(".fieldgroup.buttons", [h(SubmitButton, { form, text: "Continue" })])
    ]);
  }
}

export function validate(values) {
  return validator(values, {
    password: { password: true }
  });
}

export default form(["password", "showPassword"], validate)(NewPasswordForm);
