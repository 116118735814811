import React, { Component } from "react";
import { Router, Route, IndexRoute } from "react-router";
import { browserHistory } from "react-router";
import nextStep from "../store/next_step";
import enteringInvalidState from "../store/invalidStep";
import App from "./App";

import CheckoutMain from "./checkout_main";
import CheckoutAccordion from "./checkout_accordion";
import SigninMain from "./SigninMain";
import PasswordReset from "./PasswordReset";

class CheckoutApp extends Component {
  render() {
    const { store } = this.props;
    const history = this.props.history || browserHistory;

    return (
      <App store={store}>
        <Router history={history}>
          <Route path="/signin" component={SigninMain} />
          <Route path="/signin/:section" component={SigninMain} />
          <Route path="/password_reset/*" component={PasswordReset} />

          <Route path="/checkout" component={CheckoutMain}>
            <IndexRoute onEnter={gotoNextStep} />
            <Route
              path=":step"
              component={CheckoutAccordion}
              onEnter={enteredStep}
            />
          </Route>
        </Router>
      </App>
    );

    function gotoNextStep(nextState, replaceState) {
      const checkout = store.getState().checkout;
      const step = nextStep(checkout);

      return replaceState(`/checkout/${step}`);
    }

    function enteredStep(nextState, replaceState) {
      const checkout = store.getState().checkout;
      if (enteringInvalidState(nextState, checkout)) {
        gotoNextStep(nextState, replaceState);
      }
    }
  }
}

export default CheckoutApp;
