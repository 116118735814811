import React from "react";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import RSVP from "rsvp";

import configureStore from "checkout/store/configureStore";

import initLookup from "checkout/lookup";
import initViewCart from "checkout/view_cart";
import initRenewResults from "checkout/renew/initRenewResults";
import initPricing from "checkout/pricing/initPricing";
import { initCheckout } from "checkout/reducers/initCheckout";
import { initSignin } from "checkout/reducers/signinReducer";
import { initCartCheckout } from "checkout/reducers/initCart";
import { initBraintree, initBraintreeClient } from "checkout/braintree";
import { actions as braintreeActions } from "checkout/braintree";

import "checkout/styles/checkout.scss";

if (process.env.NODE_ENV !== "production") {
  RSVP.on("error", (reason, label) => {
    if (label) {
      console.error(label); // eslint-disable-line no-console
    }
    console.log("reason", JSON.stringify(reason, 0, 2)); // eslint-disable-line no-console
    console.assert(false, reason); // eslint-disable-line no-console
  });
}

function init(node, props) {
  const initialState = {
    signin: initSignin(props),
    checkout: initCheckout(props),
    braintree: initBraintree({}),
    cart: initCartCheckout(props.cart)
  };
  const store = configureStore(initialState);

  const actions = bindActionCreators(braintreeActions, store.dispatch);
  initBraintreeClient(props.braintree_client_id, actions);

  if (module.hot) {
    module.hot.accept("checkout/containers/CheckoutApp", () => {
      renderApp();
    });
  }
  renderApp();

  function renderApp() {
    const CheckoutApp = require("checkout/containers/CheckoutApp").default;
    ReactDOM.render(<CheckoutApp store={store} />, node);
  }
}

function checkoutProps(node) {
  const dataProps = node.getAttribute("data-props");
  return dataProps ? JSON.parse(dataProps) : null;
}

const node = document.getElementById("app");

switch (node.getAttribute("data-start")) {
  case "lookup":
    initLookup(node, checkoutProps(node));
    break;
  case "view_cart":
    initViewCart(node, checkoutProps(node));
    break;
  case "renew":
    initRenewResults(node, checkoutProps(node));
    break;
  case "pricing":
    initPricing(node, checkoutProps(node));
    break;
  default:
    init(node, checkoutProps(node) || {});
}
