import cardInfo from "../util/cardInfo";

export function fullName(first, last) {
  return [first, last].join(" ");
}

export function fmtAddress(values) {
  const { address1, address2, city, state, zip, country } = values;

  return [address1, address2, [city, state, zip, country].filter(notNull).join(" ")]
    .filter(notNull)
    .join(", ");
}

const notNull = x => !!x;

export function fmtCreditCard(values) {
  if (!values.card_number) {
    return fmtMinimalCreditCard(values);
  }

  const card = cardInfo(values.card_number);
  const cardType = findCardType(values, card);

  const last4 = last(4, values.card_number);
  const expires = `${values.expiry_month}/${values.expiry_year}`;
  return `${cardType} card ending in ${last4}, expires ${expires}`;
}

function fmtMinimalCreditCard(values) {
  if (!values.lastTwo) {
    return "";
  }
  return `${values.cardType} card ending in ${values.lastTwo}`;
}

export function fmtPhone(phone) {
  return phone;
}

const cardTypes = {
  visa: "VISA",
  mastercard: "MasterCard",
  "master-card": "MasterCard",
  discover: "Discover",
  amex: "American Express",
  "american-express": "American Express"
};

function findCardType(values, card) {
  if (card && card.type) {
    return cardTypes[card.type];
  } else if (values && values.card_type) {
    return cardTypes[values.card_type];
  } else {
    return null;
  }
}

function last(n, str) {
  if (!str) return "";
  return str.slice(str.length - n);
}
