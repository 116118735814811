import React from "react";
import styles from "cp/account_checkup/styles.module.sass";

export default function ShowEmailConfirmation(props) {
  const { email, url, actions } = props;
  const onSubmit = () => actions.showCodeVerification();
  const onClick = () => window.location.href = url;
  return (
    <div className={`email-confirmation ${styles.page}`}>
      <h1>Confirm your email address</h1>
      <p className={styles.prompt}>To ensure you receive important emails about your account and services, please confirm your email address.</p>
      <p className={styles.prompt}>We will send a verification code that will be required on the next step.</p>
      <ul className={styles.list}>
        <li className={styles.item}>
          <div className={styles.name}>Email</div>
          <div className={styles.value}>
            {email}
            <div className={styles.control}>
              <button
                className="btn-link"
                onClick={() => actions.editEmailConfirmation()}
              >
                Edit
              </button>
            </div>
          </div>
        </li>
      </ul>
      <div className={`fieldgroup buttons ${styles.buttons}`}>
        <button onClick={onClick} className="btn-link skip-button">I’ll Do This Later</button>
        <button onClick={onSubmit} className="btn">Continue</button>
      </div>
    </div>
  )
}
