import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import c from "classnames";
import { fasIcon } from "h";
import TotalLine from "./TotalLine";
import PromoCodeForm from "./PromoCodeForm";

import styles from "./cart_totals.module.sass";

function CartTotals({ cart, actions }) {
  if (cart.items.length === 0) {
    return (
      <div className={styles.empty_cart}>
        <h2>Your shopping cart is empty.</h2>
        <a className="btn primary" href="/">
          Continue Shopping
        </a>
      </div>
    );
  }

  return (
    <div className={styles.cart_totals}>
      <div className="totals">
        {cart.subtotal !== cart.total && (
          <TotalLine label="Subtotal" amount={cart.subtotal} className="subtotal" />
        )}
        {cart.icannFee !== "$0.00" && (
          <TotalLine label={icannFeeLabel()} amount={cart.icannFee} />
        )}
        {cart.discounts.map(discountLine)}
        <div className={styles.total}>
          <PromoCodeForm cart={cart} actions={actions} />
          <span
            className={c("cart-total", styles.total_amount)}
          >{`USD ${cart.total}`}</span>
        </div>
      </div>
      <form
        className={styles.submit}
        action="/cart/checkout"
        method="POST"
        onSubmit={actions.checkout}
      >
        <button id="checkout" className="btn primary">
          Secure Checkout
        </button>
        <div className={styles.note}>
          There are a few more steps before you are charged.
        </div>
      </form>
    </div>
  );

  function discountLine(discount) {
    const className = {
      discount: "discount",
      [discount.type]: discount.type,
      "text-domain-bundle": discount.type == "domain_bundles_discount",
    };
    const key = Object.values(discount).join("-");
    return (
      <TotalLine
        key={key}
        label={discountLabel(discount)}
        amount={discount.amount}
        className={className}
      />
    );
  }

  function discountLabel(discount) {
    if (discount.type === "promo") {
      return (
        <div className={c("label", styles.promo)}>
          {discount.description}
          <button
            className="btn-link remove_promo"
            onClick={() => actions.removePromoCode()}
          >
            {fasIcon("times")}
          </button>
        </div>
      );
    } else {
      return discount.description;
    }
  }

  function icannFeeLabel() {
    const feeFinder = R.compose(
      R.filter((fee) => R.defaultTo("$0.00", fee) !== "$0.00"),
      R.map((item) => item.components[0].icann_fee)
    );
    const fees = feeFinder(cart.items);
    return fees.length == 1 ? "ICANN Fee" : "ICANN Fees";
  }
}

CartTotals.propTypes = {
  cart: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

export default CartTotals;
