import React from "react";
import PropTypes from "prop-types";
import { fields, validate } from "../../email_verification/VerificationCodeForm";
import VerificationCodeInput from "../../email_verification/VerificationCodeInput";
import VerificationCodeControls from "../../email_verification/VerificationCodeControls";
import VerificationError from "../../email_verification/VerificationError";
import VerificationMessage from "../../email_verification/VerificationMessage";
import { form, ManagedForm } from "checkout/form";


function SigninTokenForm(props) {
  const { actions, fields, form, signin } = props;
  const { email, tokenError } = signin;

  const onSubmit = (values) => {
    actions.submitTokenRequest(values.code).then((data) => {
      if (data.succeeded) {
        if (data.status === "completed") {
          actions.successfulSignin(data);
        }
        return Promise.resolve();
      } else {
        throw "not_successful";
      }
    });
  }

  const onBack = () => {
    actions.goToStep("ask_email");
  }

  const onResend = () => {
    actions.initResendTokenRequest(email);
  }
  return (
    <ManagedForm form={form} onSubmit={onSubmit} className="email-verification">
      <VerificationError formError={tokenError} />
      <VerificationMessage email={email} type={"an authentication"} />
      <VerificationCodeInput updateCode={fields.code.onChange} />
      <VerificationCodeControls form={form} onBack={onBack} onResend={onResend} />
    </ManagedForm>
  );
}

SigninTokenForm.propTypes = {
  signin: PropTypes.shape({
    email: PropTypes.string.isRequired,
    remember: PropTypes.bool
  })
};

export default form(fields, validate)(SigninTokenForm);
