import PropTypes from "prop-types";
import { Component } from "react";
import { div, button } from "tags";
import c from "classnames";
import h from "h";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  TextField,
  fieldType
} from "../form";

export class TwoFactorApp extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      twoFactorCode: fieldType
    }).isRequired,
    signin: PropTypes.shape({
      note: PropTypes.string,
      error: PropTypes.string,
      loading: PropTypes.bool
    }),
    actions: PropTypes.shape({
      sendTwoFactorCode: PropTypes.func.isRequired
    }).isRequired,
    cancelButton: PropTypes.node
  };

  handleSubmit(values) {
    const { actions } = this.props;
    actions
      .sendTwoFactorCode(values.twoFactorCode)
      .then(data => actions.successfulSignin(data));
  }

  render() {
    const { form, fields, signin, cancelButton, actions } = this.props;
    const className = c("appauth", {
      loading: signin.isLoading,
      hasError: signin.error
    });
    const onSubmit = values => this.handleSubmit(values);

    const recoveryButton = button(
      ".btn-link.field-help",
      {
        type: "button",
        onClick: actions.twoFactorStartRecovery,
        tabIndex: -1
      },
      "I can't use my authenticator app"
    );

    return h(ManagedForm, { form, className, onSubmit }, [
      h(FormError, { text: signin.error }),
      div(".row.note", "Enter the code generated by your authenticator app."),
      div(".row", [
        h(TextField, {
          width: 12,
          label: "Code",
          field: fields.twoFactorCode,
          help: recoveryButton,
          autoFocus: true
        })
      ]),
      div(".signin-controls", [
        div(".buttons", [
          h(SubmitButton, { form, text: "Continue" }),
          cancelButton,
        ]),
      ]),
    ]);
  }
}

const fields = ["twoFactorCode"];

function validate(values) {
  return validator(values, {
    twoFactorCode: { presence: true }
  });
}

export default form(fields, validate)(TwoFactorApp);
