import { h, fasIcon, fromHTML } from "h";
import ReactModal from "react-modal";

function EmailOptionsPopup({ open, onClose }) {
  const style = {
    content: {
      width: "calc(80% + 60px)",
      maxWidth: "1000px",
      background: "#fff",
      border: "10px solid black",
      borderRadius: 0,
      outline: "none",
      padding: "20px",
      left: 0,
      right: 0,
      top: "40px",
      bottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      height: "auto"
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    }
  };

  const featuresHtml = stealFeaturesHtml(document.getElementById("features"));

  return h(
    ReactModal,
    {
      style,
      isOpen: open,
      onRequestClose: onClose,
      className: "email_options_popup",
      shouldCloseOnOverlayClick: false
    },
    [
      h("button.btn-link.modal-close", { onClick: onClose }, fasIcon("times")),
      h(".modal-content", [
        h("h2.modal-title", "Compare Email Options"),
        fromHTML(featuresHtml, ".mailbox_types")
      ])
    ]
  );
}

function stealFeaturesHtml(el) {
  return el.innerHTML;
}

export default EmailOptionsPopup;
