import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./number_selector.module.sass";
import { pluralize, fasIcon } from "h";

const defaultIncrement = (n) => n + 1;
const defaultDecrement = (n) => n - 1;

/**
 * Decrement value from given options.
 *
 * @param n {Number}
 * @param options {Number[]}
 * @return {Number}
 */
const decrementFromOptions = function(n, options) {
  const currOptPos = options.indexOf(n);

  // if it's the first option
  if (currOptPos == 0) {
    return n;
  } else if (currOptPos == -1) { // option is not found -- edge case
    return options[0]; // return first option
  }

  return options[currOptPos - 1];
};

/**
 * Increment value from given options.
 *
 * @param n {Number}
 * @param options {Number[]}
 * @return {Number}
 */
const incrementFromOptions = function(n, options) {
  const currOptPos = options.indexOf(n);

  // if it's the last option
  if (currOptPos == (options.length - 1)) {
    return n;
  } else if (currOptPos == -1) { // option is not found -- edge case
    return options[options.length - 1]; // return last option
  }

  return options[currOptPos + 1];
};

/**
 * @param func {Function}
 * @param getNext {Function<Number, Number[]>} Invoked with `val` & `options`
 * @param canChange {Function<Number>}
 * @param val {Number} Passed to `getNext` handler
 * @param options {Number[]} Passed to `getNext` handler
 * @param wait {Number} Wait period in milliseconds
 */
const debounce = (func, getNext, canChange, val, options, wait = 250) => {
  let timeout;
  let num = val;

  return function executedFunction() {
    const later = () => {
      timeout = null;
      if(canChange(num)){
        num = getNext(num, options);
        func(num);
      }
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

// changeHandler : Int -> Any
function NumberSelector({
  changeHandler,
  className,
  decrement = defaultDecrement,
  disabled = false,
  increment = defaultIncrement,
  max,
  min,
  options = [],
  text,
  value
}) {
  const canIncrement = (value) => value < max && !disabled;
  const canDecrement = (value) => value > min && !disabled;

  if (options && options.length > 0) {
    increment = incrementFromOptions;
    decrement = decrementFromOptions;
  }

  const onIncrement = debounce(changeHandler, increment, canIncrement, value, options);
  const onDecrement = debounce(changeHandler, decrement, canDecrement, value, options);

  const displayText = text ? pluralize(value, "Year", "Years") : value;

  return (
    <div className={c("number_selector", className)}>
      <span
        data-testid="dec"
        className={c("num-dec", styles.number_control, {
          [styles.disabled]: !canDecrement(value),
          disabled: disabled || !canDecrement(value),
        })}
        onClick={onDecrement}
      >
        {fasIcon("minus")}
      </span>
      <span className={c("num-count", styles.display_text, {[styles.display_text_wide]: text})}>{displayText}</span>
      <span
        data-testid="inc"
        className={c("num-inc", styles.number_control, {
          [styles.disabled]: !canIncrement(value),
          disabled: disabled || !canIncrement(value),
        })}
        onClick={onIncrement}
      >
        {fasIcon("plus")}
      </span>
    </div>
  );
}

NumberSelector.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default NumberSelector;
