import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import { h, linkToTargetBlank, numberToCurrency } from "h";
import { div, span } from "tags";
import {
  form,
  FormError,
  ManagedForm,
  CheckboxField,
  EmailField,
  SubmitButton
} from "../form";
import validator from "../util/validator";
import ConfirmSpinner from "./ConfirmSpinner";

class ConfirmForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    cartTotal: PropTypes.string.isRequired,
    accountSpend: PropTypes.shape({
      available: PropTypes.number.isRequired,
      spend: PropTypes.number.isRequired,
      payable: PropTypes.number.isRequired
    }),
    isZcr: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  handleSubmit(values) {
    const { isLoading, onSubmit, deviceData } = this.props;
    if (!isLoading) {
      onSubmit({ ...values, device_data: deviceData }).catch(errors => {
        this.props.form.notifySubmitErrors(errors);
      });
    }
  }

  render() {
    const {
      form,
      fields,
      isLoading,
      cartTotal,
      accountSpend,
      isZcr,
      payMethod
    } = this.props;

    const className = c(isLoading && "loading");
    const wantEmail = isZcr && fields.zcrReceiptWanted.checked;
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: form.formError }),
        div(".row", [
          h(BillingSummary, {
            cartTotal,
            accountSpend,
            payMethod
          })
        ])
      ]),
      div(".fieldgroup", [
        isZcr &&
          div(".row", [
            h(CheckboxField, {
              width: 12,
              field: fields.zcrReceiptWanted,
              label: "Email me a copy of the invoice"
            })
          ]),
        wantEmail &&
          div(".row", [
            h(EmailField, {
              width: 12,
              label: "Email Address",
              field: fields.zcrBuyerEmail
            })
          ]),
        div(".row", [
          h(CheckboxField, {
            width: 12,
            field: fields.agreeTerms,
            label: span([
              "I have read and agree to the ",
              linkToTargetBlank("Terms of Service", "https://www.hover.com/tos")
            ])
          }),
          div(".float-right", h(SubmitButton, { form, text: "Submit Order" }))
        ])
      ]),
      h(ConfirmSpinner, { isLoading })
    ]);
  }
}

function BillingSummary({ cartTotal, accountSpend, payMethod }) {

  if(accountSpend) {
    var list= document.getElementsByClassName("cart-item minicart-item");
    for (var i = 0; i < list.length; i++) {
      if ( list[i].innerHTML.includes("Account balance funding")) {
        accountSpend = null;
      }
    }
  }

  if (accountSpend) {
    return h(AccountSpend, accountSpend);
  } else {
    return h(".account-spend", [
      h(".billing-summary-line", [
        `To be paid with ${methodName(payMethod)}: USD `,
        cartTotal
      ])
    ]);
  }
}

function methodName(payMethod) {
  switch (payMethod) {
    case "new":
    case "creditcard":
      return "Credit Card";

    case "paypal":
    case "paypal_on_file":
      return "PayPal";

    case "apple_pay":
    case "apple_pay_on_file":
      return "Apple Pay";

    default:
      // Safe guess, but shouldn't happen
      return "Credit Card";
  }
}

function AccountSpend(accountSpend) {
  let parts = [
    h(".billing-summary-line.account-balance", [
      "Current Account Balance: USD ",
      numberToCurrency(accountSpend.available)
    ]),
    h("hr.faint")
  ];
  if (accountSpend.spend > 0) {
    parts.push(
      h(".billing-summary-line", [
        "To be paid with Account Balance: USD ",
        numberToCurrency(accountSpend.spend)
      ])
    );
  }
  if (accountSpend.payable > 0) {
    parts.push(
      h(".billing-summary-line", [
        "To be paid with Credit Card: USD ",
        numberToCurrency(accountSpend.payable)
      ])
    );
  }
  return h(".account-spend", parts);
}

const fields = ["agreeTerms", "zcrReceiptWanted", "zcrBuyerEmail"];

function validate(values) {
  const { zcrReceiptWanted } = values;

  return validator(values, {
    agreeTerms: { checked: true },
    zcrBuyerEmail: { presence: zcrReceiptWanted, email: zcrReceiptWanted }
  });
}

export default form(fields, validate)(ConfirmForm);
