import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import c from "classnames";
import { div } from "tags";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  TextField,
  EmailField,
  PhoneField,
  SelectField,
  ComboField
} from "../form";
import FormButtons from "./FormButtons";

import { countries as allCountries } from "common/countries";
import { stateOptions } from "../wrappers/withCountries";
import notifyChanges from "./notifyChanges";

export class RegistrationForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(["completed", "pending"]).isRequired,
    showOrgName: PropTypes.bool.isRequired,
    euRegType: PropTypes.string,
    onlyEuDomains: PropTypes.bool
  };

  handleCancel() {
    this.props.onCancel();
  }

  handleSubmit(values) {
    this.props.onSubmit(values).catch(errors => {
      this.props.form.notifySubmitErrors(errors);
    });
  }

  render() {
    const { fields, form, registration } = this.props;
    const { status, isLoading } = this.props;
    const { euRegType, onlyEuDomains } = this.props;

    const countries = countryOptions(
      allCountries,
      registration.owner_countries,
      euRegType,
      onlyEuDomains
    );
    const stateInfo = stateOptions(fields.country.value, registration.owner_states);
    const cityOptions =
      registration.owner_cities &&
      registration.owner_cities.map(city => ({ value: city, label: city }));

    const className = c({ loading: isLoading });
    const faxLabel = registration.fax_required ? "Fax" : "Fax (optional)";

    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: form.formError }),
        div(".row", [
          h(TextField, {
            width: 6,
            label: "First Name",
            field: fields.first_name,
            autoComplete: "shipping given-name"
          }),
          h(TextField, {
            width: 6,
            label: "Last Name",
            field: fields.last_name,
            autoComplete: "shipping family-name"
          })
        ]),
        div(".row", [
          h(TextField, {
            width: 12,
            label: "Organization",
            field: fields.org_name,
            autoComplete: "shipping organization"
          })
        ]),
        div(".row", [
          h(TextField, {
            width: 12,
            label: "Address 1",
            field: fields.address1,
            autoComplete: "shipping address-line1"
          })
        ]),
        div(".row", [
          h(TextField, {
            width: 12,
            label: "Address 2 (optional)",
            field: fields.address2,
            autoComplete: "shipping address-line2"
          })
        ]),
        div(".row", [
          h(SelectField, {
            width: 6,
            label: "Country",
            field: fields.country,
            options: countries,
            autoComplete: "shipping country"
          }),
          h(ComboField, {
            width: 6,
            label: stateInfo.label,
            field: fields.state,
            options: stateInfo.options,
            autoComplete: "shipping address-level1"
          })
        ]),
        div(".row", [
          h(ComboField, {
            width: 6,
            label: "City",
            field: fields.city,
            options: cityOptions,
            autoComplete: "shipping address-level2"
          }),
          h(TextField, {
            width: 6,
            label: stateInfo.zip,
            field: fields.zip,
            autoComplete: "shipping postal-code"
          })
        ]),
        div(".row", [
          h(EmailField, {
            width: 12,
            label: "Email Address",
            field: fields.email,
            autoComplete: "shipping email"
          })
        ]),
        div(".row", [
          h(PhoneField, {
            width: 6,
            label: "Phone Number",
            field: fields.phone,
            autoComplete: "shipping tel"
          }),
          h(PhoneField, {
            width: 6,
            label: faxLabel,
            field: fields.fax,
            autoComplete: "shipping fax tel"
          })
        ])
      ]),

      h(FormButtons, { form, status, onCancel: () => this.handleCancel() })
    ]);
  }
}

RegistrationForm.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};

function countryOptions(countries, ownerCountries, euRegType, onlyEuDomains) {
  if (euRegType !== "business" && onlyEuDomains) {
    return countries;
  }

  if (!ownerCountries) {
    return countries;
  } else {
    return countries.filter(country => ownerCountries.includes(country.value));
  }
}

export const fields = [
  "first_name",
  "last_name",
  "org_name",
  "address1",
  "address2",
  "country",
  "state",
  "city",
  "zip",
  "email",
  "phone",
  "fax"
];

function validate(values, props) {
  const asciiOnly = props.registration.ascii_only;
  const faxRequired = props.registration.fax_required;
  const stateRequired = ["US", "CA", "AU", "IT"].includes(values.country);

  const result =  validator(values, {
    first_name: { presence: true, asciiOnly },
    last_name: { presence: true, asciiOnly },
    org_name: { presence: props.orgNameRequired, asciiOnly },
    address1: { presence: true, asciiOnly },
    address2: { asciiOnly },
    country: { presence: true },
    city: { presence: true, asciiOnly },
    state: { presence: stateRequired },
    zip: { presence: true, postalCode: true },
    email: { presence: true, email: true, asciiOnly },
    phone: { presence: true, length: { min: 7 } },
    fax: { presence: faxRequired }
  });

  return result;
}

export default form(fields, validate)(notifyChanges(RegistrationForm));
