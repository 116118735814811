import { browserHistory } from "react-router";

function gaTrack(action, label) {
  const ga = window.ga;
  if (ga) {
    const data = {
      hitType: "event",
      eventCategory: "Checkout",
      eventAction: action,
      eventLabel: label
    };

    ga("send", data);
  }
}

browserHistory.listen(location => {
  const ga = window.ga;
  if (ga) {
    ga("set", "page", location.pathname);
    ga("send", "pageview");
  }
});

const trackingMiddleware = () => next => action => {
  const type = action.type;

  switch (type) {
    case "NAVIGATE":
      gaTrack("edit_section", action.step);
      break;

    case "NOTIFY_ENTERED_STEP":
      gaTrack("entered_step", action.step);
      break;

    case "SUBMIT_REGISTRATION_DATA":
      gaTrack("submit_form", "registration");
      break;

    case "SUBMIT_TLD_DATA": {
      const name = action.payload.tld.name.toLowerCase();
      gaTrack("submit_form", "tld_" + name);
      break;
    }

    case "SUBMIT_BILLING_DATA":
      gaTrack("submit_form", "billing");
      break;

    case "SUBMIT_ORDER":
      gaTrack("submit_order");
      break;

    case "SUCCESSFUL_SIGNIN":
      gaTrack("successful_signin");
      break;

    case "CREATE_ACCOUNT":
      gaTrack("create_account");
      break;

    case "FORGOT_USERNAME":
      gaTrack("forgot_username");
      break;

    case "FORGOT_PASSWORD":
      gaTrack("forgot_password");
      break;

    case "SKIP_SIGNIN":
      gaTrack("skip_signin");
      break;

    case "UNSKIP_SIGNIN":
      gaTrack("undo_skip_signin");
      break;

    case "SIGNIN_REMOTE_ERROR":
      gaTrack("signin_remote_error", action.error);
      break;

    case "CONFIRM_NAVIGATION":
      gaTrack("unsaved_data_dialog", action.step);
      break;

    case "CANCEL_NAVIGATION":
      gaTrack("unsaved_data_dialog_cancel");
      break;
  }

  return next(action);
};

export default trackingMiddleware;
