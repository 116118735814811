import PropTypes from "prop-types";
import c from "classnames";
import { h, fasIcon } from "h";

export function Box({ title, onClose, className, props, children }) {
  const heading = h("h2", title);
  const closeButton = onClose && h("button.close", { onClick: onClose }, fasIcon("times"));
  const boxProps = Object.assign({}, { className: c("box", className) }, props);

  return h("div", boxProps, [
    closeButton,
    heading,
    h("ul", children)
  ]);
}

Box.propTypes = {
  title: PropTypes.node.isRequired,
  onClose: PropTypes.func
};

export function BoxItem({ className, children }) {
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  return h("li", { className }, h(".boxitem-ie", h(".boxitem", children)));
}
