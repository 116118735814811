import PropTypes from "prop-types";
import { Component } from "react";
import { dl, dt, dd } from "tags";

import { fullName, fmtAddress } from "./format";

export default class RegistrationInfo extends Component {
  render() {
    const { first_name, last_name, org_name, email, phone } = this.props;
    const { orgNameRequired } = this.props;
    return dl([
      dt("Name"),
      dd(fullName(first_name, last_name)),
      orgNameRequired && dt("Organization"),
      orgNameRequired && dd(org_name),
      dt("Address"),
      dd(fmtAddress(this.props)),
      dt("Email"),
      dd(email),
      dt("Phone Number"),
      dd(phone)
    ]);
  }
}

RegistrationInfo.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  org_name: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  showOrgName: PropTypes.bool
};
