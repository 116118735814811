import React from "react";
import { fasIcon } from "h";

function UserSelect(props) {
  return (
    <div className="user-select">
      <h2>Select the account to sign in to</h2>
      <div className="user-rows">
        {props.users.map((user) => (
          <UserRow actions={props.actions} user={user} key={user} />
        ))}
      </div>
      <p className="notice">
        Not seeing your account here? Magic token sign in only works for accounts
        with a verified email address.
      </p>
    </div>
  );
}

function UserRow({ actions, user }) {
  const onClick = async () => {
    try {
      const data = await actions.initUserSelectRequest(user);
      actions.successfulSignin(data);
      return Promise.resolve();
    } catch (error) {
      throw "not successful";
    }
  }
  return (
    <div className="user-row" onClick={onClick}>
      <p>{user}</p>
      {fasIcon("chevron-right")}
    </div>
  );
}

export default UserSelect;
