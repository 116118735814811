import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { div, p } from "tags";
import { h } from "h";

export default function ConfirmSpinner({ isLoading }) {
  return h(ReactModal, { isOpen: isLoading, style, shouldCloseOnOverlayClick: false }, container(spinner()));
}

ConfirmSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const style = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    width: 202,
    height: 207,
    overflow: "hidden",
    lineHeight: 1,
    padding: 0,
    border: "none",
    background: "#fff",
    outline: "none"
  }
};

function container(content) {
  return div(".spinner-container", [content, p("Processing...")]);
}

function spinner() {
  const svg =
    "<svg width='200px' height='200px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' class='uil-balls'><rect x='0' y='0' width='100' height='100' fill='none' class='bk'></rect><g transform='rotate(0 50 50)'><circle r='5' cx='30' cy='50'><animateTransform attributeName='transform' type='translate' begin='0s' repeatCount='indefinite' dur='1s' values='0 0;20 -20' keyTimes='0;1'/><animate attributeName='fill' dur='1s' begin='0s' repeatCount='indefinite'  keyTimes='0;1' values='#fff;#999'/></circle></g><g transform='rotate(90 50 50)'><circle r='5' cx='30' cy='50'><animateTransform attributeName='transform' type='translate' begin='0s' repeatCount='indefinite' dur='1s' values='0 0;20 -20' keyTimes='0;1'/><animate attributeName='fill' dur='1s' begin='0s' repeatCount='indefinite'  keyTimes='0;1' values='#999;#000'/></circle></g><g transform='rotate(180 50 50)'><circle r='5' cx='30' cy='50'><animateTransform attributeName='transform' type='translate' begin='0s' repeatCount='indefinite' dur='1s' values='0 0;20 -20' keyTimes='0;1'/><animate attributeName='fill' dur='1s' begin='0s' repeatCount='indefinite'  keyTimes='0;1' values='#000;#fff'/></circle></g><g transform='rotate(270 50 50)'><circle r='5' cx='30' cy='50'><animateTransform attributeName='transform' type='translate' begin='0s' repeatCount='indefinite' dur='1s' values='0 0;20 -20' keyTimes='0;1'/><animate attributeName='fill' dur='1s' begin='0s' repeatCount='indefinite'  keyTimes='0;1' values='#fff;#999'/></circle></g></svg>";
  return div({ dangerouslySetInnerHTML: { __html: svg } });
}
