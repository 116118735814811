import React from "react";
import PropTypes from "prop-types";
import R from "ramda";
import h from "h";
import CartItemView from "./CartItemView";

class CartItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    loading: PropTypes.func.isRequired,
    strict: PropTypes.bool.isRequired,
    instantSavingsPromo: PropTypes.string,
    isPrimary: PropTypes.bool.isRequired,
    showPrimaryToggle: PropTypes.bool.isRequired,
    partner: PropTypes.string,
    actions: PropTypes.object.isRequired
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isPrimary !== this.props.isPrimary) {
      this.setState({ emailShown: !!this.showEmailInitially(newProps) });
    }
  }

  showEmailInitially = props => {
    return (
      props.instantSavingsPromo || countMailboxes(props.item) > 0 || props.isPrimary
    );
  };

  render() {
    const { item, loading, strict } = this.props;
    const { instantSavingsPromo } = this.props;
    const { isPrimary, showPrimaryToggle, partner } = this.props;
    const { emailShown } = this.state;
    const emailHighlight = emailShown && isPrimary;

    return h(CartItemView, {
      item,
      loading,
      strict,
      instantSavingsPromo,
      isPrimary,
      showPrimaryToggle,
      partner,
      emailShown,
      emailHighlight,
      actions: this.props.actions
    });
  }

  state = {
    emailShown: true
  };
}

function countMailboxes(item) {
  const counts = R.map(R.prop("num_emails"), item.components);
  return R.sum(R.reject(R.isNil, counts));
}

export default CartItem;
