import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { form, ManagedForm, TextField, SubmitButton, CheckboxField } from "../form";
import validator from "../util/validator";
import MagicSigninLink from "./MagicSigninLink";

function SigninEmailForm(props) {
  const { fields, signin, actions, form } = props;
  const onSubmit = (values) => {
    actions.initTokenRequest(values.email, values.remember);
  };
  return (
    <Fragment>
      <ManagedForm form={form} onSubmit={onSubmit} className="email-verification-form">
        <div className="row">
          <TextField width={12} label={"Email"} field={fields.email} />
        </div>
        <div className={"signin-controls"}>
          <CheckboxField
            width={12}
            field={fields.remember}
            label={"Keep me signed in"}
          />
          <SubmitButton form={form} text={"Send Token"} />
        </div>
      </ManagedForm>
      <MagicSigninLink step={signin.step} onClick={actions.goToStep} />
    </Fragment>
  );
}

const fields = ["email", "remember"];

function validate(values) {
  return validator(values, {
    email: {
      presence: true,
      email: true,
    },
  });
}

SigninEmailForm.propTypes = {
  actions: PropTypes.shape({
    initTokenRequest: PropTypes.func.isRequired,
  }).isRequired,
};

export default form(fields, validate)(SigninEmailForm);
