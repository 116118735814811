import { receivedTerms } from "../actions/ciraActions";

export default store => next => action => {
  if (action.type === "ENTERED_STEP" && action.payload === "tld_ca") {
    const element = document.getElementById("cira-terms");
    if (element) {
      store.dispatch(receivedTerms(element.innerHTML));
    }
  }

  return next(action);
};
