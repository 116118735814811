import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import { div } from "tags";

export default class ScrollingAgreement extends Component {
  render() {
    const { content, className: cls } = this.props;
    const className = c("scrolling-agreement", cls);
    return div({
      className,
      dangerouslySetInnerHTML: { __html: content }
    });
  }
}

ScrollingAgreement.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string
};
