import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import h from "h";
import validator from "../util/validator";
import { div, button, p } from "tags";
import {
  form,
  ManagedForm,
  SubmitButton,
  FormError,
  TextField,
  fieldType
} from "../form";

export class ForgotPassword extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      username: fieldType.isRequired
    }).isRequired,
    signin: PropTypes.shape({
      note: PropTypes.string
    }).isRequired,
    actions: PropTypes.shape({
      submitPasswordRecovery: PropTypes.func.isRequired,
      signinCancel: PropTypes.func.isRequired
    }).isRequired
  };

  handleSubmit(values) {
    const { actions } = this.props;
    actions.submitPasswordRecovery(values.username);
  }

  render() {
    const { form, fields, signin, actions } = this.props;
    const className = c("forgot_password", {
      loading: signin.isLoading,
      hasError: signin.error
    });
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: signin.error }),
        div(".row", [
          p(
            ".note",
            "Give us your Hover username and we'll send you a link to reset your password."
          )
        ]),
        div(".row", [
          h(TextField, {
            width: 12,
            label: "Username",
            field: fields.username,
            autoFocus: true
          })
        ])
      ]),
      div(".fieldgroup.buttons", [
        button(
          ".btn-link .form_cancel",
          { type: "button", onClick: actions.signinCancel },
          "Cancel"
        ),
        h(SubmitButton, { form, text: "Send" })
      ])
    ]);
  }
}

const fields = ["username"];

export function validate(values) {
  return validator(values, {
    username: { presence: true }
  });
}

export default form(fields, validate)(ForgotPassword);
