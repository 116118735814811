import PropTypes from "prop-types";
import { h } from "h";
import { div, p, a } from "tags";
import { CheckboxField } from "../form";

import ScrollingAgreement from "./ScrollingAgreement";
import "../styles/cira.scss";

export default function CiraAgreement({ content, field }) {
  return div(".fieldgroup", [
    p(
      ".row",
      `
      The Canadian Internet Registration Authority (CIRA) is
      responsible for operating the .CA registry on behalf of all
      Canadians. They require that all .ca domain registrants view and
      agree to the CIRA Registrant Agreement.
    `
    ),
    p([
      a(
        {
          target: "_blank",
          href: "https://rr-n1-tor.opensrs.net/cira_agreement.cgi"
        },
        "Printable version"
      )
    ]),
    h(ScrollingAgreement, { content, className: "row" }),
    div(".row", [
      h(CheckboxField, {
        width: 12,
        label: "I agree",
        field
      })
    ]),
    p(".row", [
      `By checking "I Agree" you confirm that you have read
      and agree to the CIRA Registrant Agreement.`
    ])
  ]);
}

CiraAgreement.propTypes = {
  field: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired
};
