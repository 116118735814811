import React from "react";
import PropTypes from "prop-types";
import CPToolTip from "cp/tooltip/Tooltip";
export default function Tooltip({ onShow, tooltip }) {
  return (
    <span className="tip form-tip">
      <CPToolTip afterShow={onShow} id={name} text={tooltip}>
        <i className="fas fa-info-circle"/>
      </CPToolTip>
    </span>
  )
}

Tooltip.propTypes = {
  onShow: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
}

export function TieredPriceInfoToolTip({ name }) {
  const msg =
    "Tiered price domains are priced differently by the registry, " +
    "so the renewal price may be higher or lower " +
    "than our regular price.";
  return (
    <span className="tip">
      <CPToolTip id={name + "tiered-price"} text={msg} className="lookup-inner">
        <i className="fas fa-info-circle"/>
      </CPToolTip>
    </span>
  )
}

TieredPriceInfoToolTip.propTypes = {
  name: PropTypes.string.isRequired
}
