import React, { useEffect } from "react";
import VerificationCodeForm from "../../email_verification/VerificationCodeForm";
import { sendVerificationEmailEdit } from "../../email_verification/requests";

export default function SigninVerification(props) {
  const { email, actions, error } = props;
  const onBack = () => {
    const { emailVerified, url } = props;
    actions.showEmailConfirmation({
      email,
      emailVerified,
      url
    });
  }
  const sendEmail = () => {
    sendVerificationEmailEdit({ email });
  }
  useEffect(() => {
    sendEmail()
  }, []);
  const onSubmit = async (values) => {
    actions.submitVerificationCode(values.code);
  };
  return <VerificationCodeForm
    className="email-verification"
    onSubmit={onSubmit}
    onResend={() => sendEmail()}
    onBack={() => onBack()}
    email={email}
    formError={error}
  />;
}
