// import { PropTypes } from "react";
import { h } from "h";
import get from "lodash/get";
import find from "lodash/find";
import Summary from "./Summary";
import TldEuSummary from "./TldEuSummary";
import { countries } from "common/countries";

function TldInfo({ params, values }) {
  const items = params.map(param => {
    const value = get(values, param.key, "");
    return [param.label, displayValue(param, value), param.required];
  });
  if(values.ext === "EU") {
    return h(TldEuSummary, { items });
  } else {
    return h(Summary, { items });
  }
}

function displayValue(param, value) {
  if (param.type === "country") {
    return displayCountryName(value);
  } else if (param.type === "ngo_agreeTerms") {
    return displayTerms(value);
  } else if (param.type === "app_agreeTerms") {
    return displayTerms(value);
  } else if (param.type === "page_agreeTerms") {
    return displayTerms(value);
  } else if (param.type === "dev_agreeTerms") {
    return displayTerms(value);
  } else if (param.options) {
    return displayValueFromOptions(param.options, value);
  } else {
    return value;
  }
}

function displayValueFromOptions(options, value) {
  const labelAndVal = find(options, opt => opt[1] === value);

  if (labelAndVal) {
    return labelAndVal[0];
  } else {
    return value;
  }
}

function displayCountryName(shortCode) {
  const country = countries.find(opt => opt.value === shortCode);
  if (country) {
    return country.label;
  } else {
    return shortCode;
  }
}

function displayTerms(value) {
  return value ? "Yes" : "No";
}

export default TldInfo;
