import { Component } from "react";
import { h } from "h";

import { fmtCreditCard, fmtAddress, fmtPhone } from "./format";
import Summary from "./Summary";

export default class BillingInfo extends Component {
  render() {
    const { billing } = this.props;
    return h(Summary, { items: makeItems(billing) });
  }
}

function makeItems(billing) {
  const { phone, pay_method: payMethod } = billing;

  const existingPayment = billing.payment_on_file || {};

  if (payMethod === "new") {
    return [
      ["Name", billing.full_name],
      ["Payment", fmtCreditCard(billing)],
      ["Billing Address", fmtAddress(billing)],
      ["Phone Number", fmtPhone(phone)]
    ];
  } else if (payMethod === "creditcard") {
    return [
      ["Name", existingPayment.name_on_card],
      ["Payment", fmtCreditCard(existingPayment)],
      ["Billing Address", fmtAddress(billing)],
      ["Phone Number", fmtPhone(phone)]
    ];
  } else if (payMethod === "paypal") {
    return [["Paypal", billing.ppe_email]];
  } else if (payMethod === "paypal_on_file") {
    return [["Paypal", existingPayment.ppe_email]];
  } else if (payMethod === "apple_pay") {
    return [["Apple Pay", billing.description]];
  } else if (payMethod === "apple_pay_on_file") {
    return [["Apple Pay", existingPayment.description]];
  }
}
