import PropTypes from "prop-types";
import { Component } from "react";
import ReactModal from "react-modal";
import { h, fasIcon } from "h";

export default class ConfirmNavigation extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  render() {
    const { isOpen, onConfirm, onCancel } = this.props;
    return h(ReactModal, { isOpen, onRequestClose: onCancel, style: this.style, shouldCloseOnOverlayClick: false }, [
      h(".modal-header", [
        h("button.btn-link.closeButton", { onClick: onCancel }, fasIcon("times")),
        h("h4", "Confirm Navigation")
      ]),
      h(".modal-body", [
        h("p", "You have unsaved changes, which will be lost if you continue.")
      ]),
      h(".modal-footer", [
        h("button.btn-link.cancel", { onClick: onCancel }, "Cancel"),
        h("button.btn.primary.continue", { onClick: onConfirm }, "Proceed")
      ])
    ]);
  }

  style = {
    content: {
      width: "750px",
      left: 0,
      right: 0,
      top: "40px",
      bottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      boxShadow: "0px 0px 30px 5px black",
      height: "auto"
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    }
  };
}
