import { Component } from "react";
import { connect } from "react-redux";
import { h } from "h";
import Section from "../components/Section";
import MailboxForm from "../components/MailboxForm";
import MailboxInfo from "../components/MailboxInfo";
import { submitMailboxData } from "../actions/checkout_actions";
import { formData } from "../reducers/index";

class MailboxStep extends Component {
  render() {
    const { initialValues, partner, primaryDomainName, onSubmit } = this.props;

    const viewer = () =>
      h(MailboxInfo, {
        primaryDomainName,
        username: initialValues.username,
        forwardTo: initialValues.forward_to
      });
    const editor = () =>
      h(MailboxForm, {
        primaryDomainName,
        initialValues,
        partner,
        onSubmit
      });

    return h(Section, {
      ...this.props,
      title: "Email and forwarding setup",
      viewer: viewer,
      editor: editor
    });
  }
}

function mapStateToProps(state) {
  const mailboxData = formData(state, "mailbox");
  return {
    initialValues: mailboxData,
    partner: state.cart.partnerName,
    primaryDomainName: state.cart.primaryDomainName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: values => dispatch(submitMailboxData(values))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MailboxStep);
