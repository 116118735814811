import Immutable from "seamless-immutable";
import curry from "lodash/curry";

export default function invalidStep(routerState, checkoutState) {
  const stepName = routerState.params.step;
  const steps = checkoutState.model.steps;
  return !isStepEditable(steps, stepName);
}

export const isStepEditable = curry((steps, stepName) => {
  const stepNames = steps.map(s => s.name);

  if (stepNames.includes(undefined)) {
    throw new Error();
  }

  const validities = statesToValidities(stepNames, stepStates(steps));
  return validities[stepName];
});

export function stepState(step) {
  return step.status;
}

export function stepStates(steps) {
  let states = {};
  for (const step of steps) {
    const state = stepState(step);
    states[step.name] = state;
  }
  return Immutable(states);
}

export function statesToValidities(stepNames, statuses) {
  let validities = {};
  let seenPending = false;

  for (const stepName of stepNames) {
    switch (statuses[stepName]) {
      case "locked":
        validities[stepName] = false;
        break;

      case "pending":
        if (seenPending) {
          validities[stepName] = false;
        } else {
          validities[stepName] = true;
          seenPending = true;
        }
        break;

      case "completed":
        validities[stepName] = true;
        break;
    }
  }

  return Immutable(validities);
}
