import PropTypes from "prop-types";
import { Component } from "react";
import React from "react";
import ReactModal from "react-modal";
import merge from "lodash/merge";
import SedoLogo from "./images/sedo_logo.svg";
import DALogo from "application/images/home/lookup/domainagents.png";

import { h, fasIcon, gaTrackEvent } from "h";

class OfferPopup extends Component {
  static propTypes = {
    model: PropTypes.shape({
      offerDialog: PropTypes.bool.isRequired,
      sedoDialog: PropTypes.bool.isRequired,
      sendAction: PropTypes.func.isRequired,
      offerDomain: PropTypes.string
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      amLoading: false
    };
  }

  render() {
    const { offerDialog, sedoDialog } = this.props.model;
    const open = offerDialog || sedoDialog;
    const amLoading = this.state.amLoading;

    const { logo, text } = this.modalContent();

    return h(
      ReactModal,
      {
        isOpen: open,
        onRequestClose: () => this.onClose(),
        style: OfferPopup.style(),
        shouldCloseOnOverlayClick: false
      },
      loading([
        h(
          "button.btn-link.closeButton",
          { onClick: () => this.onClose() },
          fasIcon("times")
        ),
        h(".content", [h("img.logo", { src: logo }), h("p", text)]),
        h(".options", [
          h(
            "button.btn.primary.continue",
            { onClick: () => this.learnMore() },
            "LEARN MORE"
          )
        ])
      ])
    );

    function loading(content) {
      if (amLoading) {
        return h(".loading", content);
      } else {
        return content;
      }
    }
  }

  modalContent() {
    if (this.props.model.offerDialog) {
      return {
        logo: DALogo,
        text: [
          "Hover has partnered with DomainAgents to help you acquire domain names that are owned by others, but that might be for sale. With DomainAgents help, you can open a negotiation with the current owner of the domain. Your offer will be presented to the current owner, guaranteed."
        ]
      };
    } else {
      return {
        logo: SedoLogo,
        text: [
          "Hover has partnered with Sedo, a leading domain name marketplace to help you find the perfect domain name. ",
          h("span.bold", this.props.model.offerDomain),
          " is listed for sale in the Sedo marketplace. Clicking below will take you there to start the negotiation process."
        ]
      };
    }
  }

  onClose() {
    this.props.model.sendAction({ name: "closeOfferDialog" });
  }

  learnMore() {
    const trackName = this.props.model.offerDialog
      ? "click_domainagents_modal"
      : "click_sedo_modal";
    const actionName = this.props.model.offerDialog ? "offerLearnMore" : "offerSedo";
    this.setState({ amLoading: true });
    if (this.props.model.offerDialog) {
      this.props.model.sendAction({
        name: "offerLearnMore",
        domainName: this.props.model.offerDomain
      });
    } else {
      this.props.model.sendAction({
        name: "offerSedo",
        domainName: this.props.model.offerDomain,
        sedoPartnerUrl: this.props.model.sedoPartnerUrl
      });
    }
    gaTrackEvent("DomainLookup", trackName, this.props.model.offerDomain);
    this.props.model.sendAction({
      name: actionName,
      domainName: this.props.model.offerDomain
    });
  }

  static style() {
    const baseStyle = {
      content: {
        width: "675px",
        transform: "translate(0, 50%)",
        bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        marginLeft: "auto",
        marginRight: "auto",
        height: "380px",
        borderRadius: 0,
        zIndex: 3,
        paddingBottom: "50px"
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 2
      }
    };
    if (window.matchMedia("(max-width: 480px)").matches) {
      return merge(baseStyle, {
        content: {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          width: "100%",
          height: "480px",
          transform: "translate(0, 30%)",
        }
      });
    } else {
      return baseStyle;
    }
  }
}

export default OfferPopup;
