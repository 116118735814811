import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Immutable from "seamless-immutable";
import * as checkoutActions from "../actions/checkout_actions";
import * as braintreeActions from "../braintree/actions";
import { isLoading } from "../reducers/checkoutReducer";
import * as queries from "../reducers";

import { h } from "h";

import Section from "../components/Section";
import BillingInfo from "../components/BillingInfo";
import BillingForm from "../components/BillingForm";

class BillingStep extends Component {
  render() {
    const { cardData, stepData, actions } = this.props;
    const { onSubmit, onCancel } = actions;

    return h(Section, {
      ...this.props,
      title: "Billing Info",
      viewer: () =>
        h(BillingInfo, {
          key: "billing_info",
          billing: this.props.billing
        }),
      editor: () =>
        h(BillingForm, {
          ...this.props,
          key: "billing_form",
          currentValues: cardData,
          onSubmit,
          onCancel,
          status: stepData.status
          // initialValues,
          // braintree,
          // billing,
          // cardData,
          // cartTotal,
          // isLoading,
          // formError,
          // actions,
        })
    });
  }
}

function mapStateToProps(state) {
  const billingData = queries.billingData(state);
  const braintreeData = queries.braintreeProxyFields(state);
  return {
    initialValues: Immutable({
      pay_method: "new",
      ...billingData,
      ...braintreeData
    }),
    cardData: braintreeData,
    isLoading: isLoading(state.checkout),
    braintree: state.braintree,
    cartTotal: state.cart.total
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      ...checkoutActions,
      ...braintreeActions,
      onSubmit: checkoutActions.submitBillingData,
      onCancel: checkoutActions.redirectToNextStep
    },
    dispatch
  );
  return {
    actions: actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingStep);
