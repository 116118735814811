export function signinRequest(username, password, remember, token) {
  return {
    type: "SIGNIN_REQUEST",
    username,
    password,
    remember,
    token,
    remote: true
  };
}

export function signinResponse(serverResponseJSON) {
  return {
    type: "SIGNIN_RESPONSE",
    payload: serverResponseJSON
  };
}

export function forgotUsername() {
  return {
    type: "FORGOT_USERNAME"
  };
}

export function forgotPassword() {
  return {
    type: "FORGOT_PASSWORD"
  };
}

export function signinCancel() {
  return {
    type: "SIGNIN_CANCEL"
  };
}

export function startResendSms() {
  return {
    type: "RESEND_SMS"
  };
}

export function resendSmsRequest() {
  return {
    type: "RESEND_SMS_REQUEST",
    remote: true
  };
}

export function resendSmsCancel() {
  return {
    type: "RESEND_SMS_CANCEL"
  };
}

export function resendSmsResponse(data) {
  return {
    type: "RESEND_SMS_RESPONSE",
    data
  };
}

export function sendTwoFactorCode(code) {
  return {
    type: "AUTH_2FA_REQUEST",
    code,
    remote: true
  };
}

export function twoFactorResponse(data) {
  return {
    type: "AUTH_2FA_RESPONSE",
    data
  };
}

export function twoFactorStartRecovery() {
  return {
    type: "2FA_RECOVERY"
  };
}

export function twoFactorRecoveryRequest(recoveryCode) {
  return {
    type: "2FA_RECOVERY_REQUEST",
    remote: true,
    recoveryCode
  };
}

export function twoFactorRecoveryResponse(data) {
  return {
    type: "2FA_RECOVERY_RESPONSE",
    data
  };
}

export function twoFactorCancelRecovery() {
  return {
    type: "2FA_RECOVERY_CANCEL"
  };
}

export function submitPasswordRecovery(username) {
  return {
    type: "SUBMIT_PASSWORD_RECOVERY",
    remote: true,
    username
  };
}

export function passwordRecoveryResponse(response) {
  return {
    type: "PASSWORD_RECOVERY_RESPONSE",
    response
  };
}

export function confirmSkipSignin() {
  return {
    type: "CONFIRM_SKIP_SIGNIN"
  };
}

export function cancelSkipSignin() {
  return {
    type: "CANCEL_SKIP_SIGNIN"
  };
}

export function skipSignin() {
  return {
    type: "SKIP_SIGNIN",
    remote: true
  };
}

export function unSkipSignin() {
  return {
    type: "UNSKIP_SIGNIN",
    remote: true
  };
}

export function signinRemoteError(error) {
  return {
    type: "SIGNIN_REMOTE_ERROR",
    error
  };
}

export function mainSigninSuccess(data) {
  return {
    type: "MAIN_SIGNIN_SUCCESS",
    data,
    remote: true
  };
}

export function toggleAccountForm() {
  return  {
    type: "TOGGLE_ACCOUNT_FORM"
  }
}

export function goToStep(step) {
  return  {
    type: "SIGNIN_GO_TO_STEP",
    step
  }
}

export function initTokenRequest(email, remember) {
  return {
    type: "INIT_TOKEN_REQUEST",
    email,
    remember,
    remote: true
  };
}

export function initResendTokenRequest(email, remember) {
  return {
    type: "INIT_RESEND_TOKEN_REQUEST",
    email,
    remember,
    remote: true
  };
}

export function initTokenResponse(data) {
  return {
    type: "INIT_TOKEN_RESPONSE",
    data
  };
}

export function submitTokenRequest(token) {
  return {
    type: "SUBMIT_TOKEN_REQUEST",
    token,
    remote: true
  };
}

export function submitTokenResponse(data) {
  return {
    type: "SUBMIT_TOKEN_RESPONSE",
    data
  };
}

export function setTokenError(errors) {
  return {
    type: "SET_TOKEN_ERROR",
    errors
  };
}

export function clearTokenError() {
  return {
    type: "CLEAR_TOKEN_ERROR"
  };
}

export function initUserSelectRequest(username) {
  return {
    type: "INIT_USER_SELECT_REQUEST",
    username,
    remote: true
  }
}

export function showEmailConfirmation(payload) {
  return {
    type: "SIGNIN_EMAIL_CONFIRMATION",
    payload
  }
}

export function editEmailConfirmation() {
  return {
    type: "SIGNIN_EDIT_EMAIL"
  }
}

export function submitEmailUpdate(payload) {
  return {
    type: "SIGNIN_SUBMIT_EMAIL",
    payload
  }
}

export function showCodeVerification() {
  return {
    type: "SIGNIN_CODE_VERIFICATION"
  }
}

export function submitVerificationCode(code) {
  return {
    type: "SIGNIN_SUBMIT_VERIFICATON",
    remote: true,
    payload: {
      code
    }
  }
}

export function setVerificationError(error) {
  return {
    type: "SIGNIN_SET_VERIFICATON_ERROR",
    payload: {
      error
    }
  }
}

export function goBack() {
  return {
    type: "SIGNIN_GO_BACK"
  }
}
