import get from "lodash/get";

const getEuRegType = checkout => {
  const step = checkout.model.steps.filter(step => step.name === "tld_eu")[0];
  return get(step, "meta.values.eu_registration_type");
}

const onlyEu = cart => numRegistrations(cart.items) === numEu(cart.items);

const numRegistrations = items => items
  .filter(i => i.type === "domain").length;

const numEu = items => items
  .filter(i => i.type === "domain" && i.name.split(".")[1] === "eu").length;

const disclaimer = `Individual type domains will be private in the EU WHOIS
database. Business type registrations will be public in the EU WHOIS database.
Note that business type registrations require an organization.`;

export {
  getEuRegType,
  onlyEu,
  disclaimer
}
