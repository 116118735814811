import PropTypes from "prop-types";
import { Component } from "react";
import c from "classnames";
import h from "h";
import validator from "../util/validator";
import { div, button, p } from "tags";
import {
  form,
  ManagedForm,
  SubmitButton,
  FormError,
  EmailField,
  fieldType
} from "../form";

export class ForgotUsername extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      email: fieldType.isRequired
    }).isRequired,
    signin: PropTypes.shape({
      note: PropTypes.string,
      error: PropTypes.string
    }).isRequired,
    actions: PropTypes.shape({
      submitPasswordRecovery: PropTypes.func.isRequired,
      signinCancel: PropTypes.func.isRequired
    }).isRequired
  };

  handleSubmit(values) {
    const { actions } = this.props;
    actions.submitPasswordRecovery(values.email);
  }

  render() {
    const { form, fields, signin, actions } = this.props;
    const className = c("forgot_username", {
      loading: signin.isLoading,
      hasError: signin.error
    });
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: signin.error }),
        div(".row", [
          p(
            ".note",
            `If you don't know your Hover username,
            provide the email address associated with your account
            and we'll send you an email with your username.`
          )
        ]),
        div(".row", [
          h(EmailField, {
            width: 12,
            label: "Email Address",
            field: fields.email,
            autoFocus: true
          })
        ])
      ]),
      div(".fieldgroup.buttons", [
        button(
          ".btn-link .form_cancel",
          { type: "button", onClick: actions.signinCancel },
          "Cancel"
        ),
        h(SubmitButton, { form, text: "Send" })
      ])
    ]);
  }
}

const fields = ["email"];

export function validate(values) {
  return validator(values, {
    email: { presence: true, email: true }
  });
}

export default form(fields, validate)(ForgotUsername);
