import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import c from "classnames";
import get from "lodash/get";

import { h, fasIcon, pluralize } from "h";
import Filters from "./filters";
import OfferPopup from "./offer_popup";
import Category from "./category";

class DomainLookup extends Component {
  UNSAFE_componentWillMount() {
    this.modalDialogHtml = $(".domainagents").html();
  }

  render() {
    const model = this.props.model.toJS();
    const { integration } = model;
    const { categories, qparams, cart, filters } = model;
    const { sendAction } = model;
    const progress = progressInfo(this.props.model);
    const sectionClass = c({ integration: integration });
    return h("div", [
      newSearch({ cart, qparams }),
      h("section.herop", { className: sectionClass }, [
        integration && h(PartnerHeading, integration),
        h(ProgressBar, progress),
        renderExactMatch(model, model.groupedResults.exact || []),
        h(".extra-results", [
          h(".results-left", [h(Results, model)]),
          h(".results-right", [h(Filters, { categories, filters, sendAction })]),
          h(OfferPopup, {
            model
          })
        ])
      ])
    ]);

    function onRemoveFromCart(name) {
      sendAction({ name: "removeFromCart", item: name });
    }

    function onEmptyCart() {
      sendAction({ name: "emptyCart" });
    }
  }
}

const miniCart = props => {
  const itemCount = props.items.length;
  const itemText = pluralize(itemCount, "ITEM", "ITEMS");
  return h(
    "a.new-minicart-holder",
    { href: "/cart" },
    h(".new-minicart", [
      h("p", [fasIcon("shopping-cart"), h("span.count", itemText)]),
      h("p.amount", "USD " + props.subtotal)
    ])
  );
};

const newSearch = props => {
  return h(
    ".new-header",
    h("section.herop", [
      h(".new-search", [h(SearchForm, props.qparams)]),
      miniCart(props.cart)
    ])
  );
};

const Heading = integration => {
  if (integration) {
    return [
      h(PartnerHeading, integration),
      integration.offer &&
        h(".offer", [fasIcon("ticket"), h("p", integration.offer)])
    ];
  } else {
    return [h(SearchHeading)];
  }
};

const SearchHeading = () =>
  h(".search_heading", [
    h("h1", "Here are some great domains for your website or idea"),
    h("p", "We make it easy to register and connect with just a few clicks.")
  ]);

const PartnerHeading = ({ title, path, landing_page_variant, offer }) => {
  return h(".search_heading.partner", [
    h(PartnerLogo, { src: path, size: 60 }),
    ...partnerHeading(title, landing_page_variant, offer)
  ]);
};

function partnerHeading(title, landing_page_variant, offer) {
  if (offer && offer != "") {
    return [
      h("h1", `Get a great domain courtesy of ${title}`),
      h("p", "We make it easy to register and connect it with just a few clicks.")
    ];
  } else if (landing_page_variant === "generic") {
    return [
      h("h1", "Here are some great domains for your website or idea"),
      h("p", "We make it easy to register and connect it with just a few clicks.")
    ];
  } else if (landing_page_variant === "plain") {
    return [
      h("h1.variant-plain", `Register a domain to use with ${title}`),
      h(
        "p",
        `We’ll automatically add an email forward ($5/year) to complete your package for use with ${title}.`
      )
    ];
  } else {
    return [
      h("h1", `Get a great domain for your ${title} website.`),
      h("p", "We make it easy to register and connect it with just a few clicks.")
    ];
  }
}

class PartnerLogo extends Component {
  render() {
    const { src, size } = this.props;
    return h("img.logo", { src, width: size, height: size });
  }
}

class SearchForm extends Component {
  render() {
    const { q } = this.props;

    return h("form.search", { action: "/domains/results" }, [
      h("input#searchText", {
        name: "q",
        type: "text",
        defaultValue: q,
        autoCapitalize: "none"
      }),
      h("button.btn.search.primary", [fasIcon("search")]),
      hiddenInput("tld", this.props.tld),
      hiddenInput("only", this.props.only)
    ]);
  }
}

class ProgressBar extends Component {
  static propTypes = {
    status: PropTypes.oneOf(["in_progress", "completed", "hidden"]).isRequired,
    count: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    registries: PropTypes.number.isRequired
  };

  render() {
    const { status, count, percent, registries } = this.props;

    if (status !== "hidden") {
      const message = progressMessage();
      const style = { width: percent.toFixed(1) + "%" };
      const progressBar = h(".progress", [
        h(".status", message),
        h(".progress_bar", [h("span", { style })]),
        h(".space")
      ]);
      return fadeSlide([progressBar]);
    } else {
      return fadeSlide([]);
    }

    function progressMessage() {
      if (status === "completed") return "Completed.";

      const searching = pluralize(registries, "registry", "registries");
      const found = pluralize(count, "recommendation");
      return `Searching ${searching}, ${found} found.`;
    }

    function fadeSlide(items) {
      return h(
        TransitionGroup,
        items.map(item => h(
          CSSTransition,
          {
            classNames: "progress",
            enter: false,
            timeout: {leave: 500}
          },
          item
        ))
      );
    }
  }
}

function renderExactMatch(props, matches) {
  if (!matches || matches.length === 0) return;
  const top = get(props, ["groupedResults", "top"]);
  const highlight = matches.length > 0 && matches[0].status !== "taken";
  return h(
    ".results",
    renderCategory(props, "Exact Match", matches, "exact", null, highlight)
  );
}

function renderCategory(props, heading, matches, categoryId, integration, hl) {
  if (!matches || matches.length === 0) return;
  return h(Category, {
    ...props,
    heading,
    matches,
    categoryId,
    integration,
    hl
  });
}

class Results extends Component {
  render() {
    const props = this.props;
    const { integration, categories, groupedResults } = this.props;

    return h(".results", [
      errorMessage(),
      renderTopPicksCategory(integration, groupedResults.top || []),
      ...categories.map(cat =>
        renderCategory(props, cat.title, groupedResults[cat.id], cat.id)
      )
    ]);

    function errorMessage() {
      const error = props.error;
      if (error) {
        const rawMarkup = { __html: error };
        return h(".message", [h("h2", { dangerouslySetInnerHTML: rawMarkup })]);
      }
    }

    function renderTopPicksCategory(integration, matches) {
      const heading = exactMatchHeading(matches.length);
      return renderCategory(props, heading, matches, "top", integration);
    }

    function exactMatchHeading(integration, count) {
      return count === 1 ? "Top Pick" : "Top Picks";
    }
  }
}

function hiddenInput(name, value) {
  return value ? h("input", { type: "hidden", name: name, value: value }) : null;
}

function progressInfo(model) {
  const hideStatusBar = model.getIn(["progress", "hidden"]);
  const count = model.getIn(["progress", "count"], 0);
  const estMax = model.getIn(["progress", "max"], 300);
  const inProgress = model.get("lookupInProgress");
  const registries = model.get("numRegistries", 300);

  const max = inProgress ? progressMax(estMax, count) : count;
  const percent = 100 * count / max;
  const status = mkStatus(inProgress, hideStatusBar);

  return { status, count, max, percent, registries };

  function progressMax(max, count) {
    if (count <= max) return max;
    else return count + 10;
  }

  function mkStatus(inProgress, hideStatusBar) {
    if (hideStatusBar) return "hidden";
    else if (inProgress) return "in_progress";
    else return "completed";
  }
}

export default DomainLookup;
