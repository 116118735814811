import React from "react";
import validator from "../util/validator";
import EmailForm from "cp/settings/overview/EmailForm";

export default function EditEmailConfirmation(props) {
  const { email, newsletters,actions, fields } = props;
  const onSubmit = (values) => {
    actions.submitEmailUpdate(values);
  };
  return (
    <div className="email-confirmation">
      <h1>Confirm your email address</h1>
      <EmailForm
        initialValues={{
          email,
          newsletters
        }}
        onSubmit={onSubmit}
        onBack={() => actions.goBack()}
        emailVerificationEnabled={true}
        hideHeader={true}
      />
    </div>
  )
}

const fields = ["email", "newsletters", "newsletters_consent"];

function validate(values) {
  return validator(values, {
    email: {
      presence: true,
      email: true
    },
    newsletters_consent: {
      checked: values.newsletters
    }
  });
}
