import PropTypes from "prop-types";
import React from "react";
import { Component } from "react";
import Immutable from "immutable";

import { h, farIcon } from "h";

class Filters extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
    integration: PropTypes.object,
    sendAction: PropTypes.func.isRequired
  };

  render() {
    const { categories, filters, integration, sendAction } = this.props;

    const filterSet = Immutable.Set(filters);

    return h(".filters", [
      h("h2", "Filters"),
      h("form", [
        filter("all", "Show All"),
        ...categories.map(c => filter(c.id, c.title))
      ])
    ]);

    function filter(id, title) {
      const icon = filterSet.has(id) ? "check-square" : "square";
      return h("label", { onClick: () => toggle(id) }, [farIcon(icon), h("span", title)]);
    }

    function toggle(id) {
      sendAction({
        name: "toggleCategory",
        id: id,
        checked: !filterSet.has(id)
      });
    }

    function topHeading() {
      if (integration) {
        return "Top Picks for " + integration.title;
      } else {
        return "Top Picks";
      }
    }
  }
}

export default Filters;
