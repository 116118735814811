import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import c from "classnames";
import { div } from "tags";
import {
  form,
  ManagedForm,
  FormError,
  StaticField,
  TextField,
  EmailField
} from "../form";
import FormButtons from "./FormButtons";
import validator from "../util/validator";

export class MailboxForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    primaryDomainName: PropTypes.string.isRequired,
    partner: PropTypes.string.isRequired
  };

  handleCancel() {
    this.props.onCancel();
  }

  handleSubmit(values) {
    this.props.onSubmit(values).catch(errors => {
      this.props.notifySubmitErrors(errors);
    });
  }

  render() {
    const { fields, form, primaryDomainName, partner } = this.props;
    const { status, isLoading } = this.props;
    const className = c({ loading: isLoading });

    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: form.formError }),
        div(".row", [
          h(TextField, {
            width: 6,
            label: "Address",
            field: fields.username
          }),
          h(
            StaticField,
            { width: 6, label: "\xa0" },
            h("strong.align-text-field", `@${primaryDomainName}`)
          )
        ]),
        h(
          ".help-sub",
          `This will be the new From address you can use with ${partner}.`
        ),
        div(".row", [
          h(EmailField, {
            width: 12,
            label: "Forward to",
            field: fields.forward_to
          })
        ]),
        h(
          ".help-sub",
          `Enter your regular email address here. We’ll forward emails sent to your new ${primaryDomainName} inbox to this address.`
        )
      ]),

      h(FormButtons, { form, status, onCancel: () => this.handleCancel() })
    ]);
  }
}

const fields = [
  "mailbox_type",
  "username",
  "password",
  "showPassword",
  "forward_to"
];

function validate(values) {
  return validator(values, {
    username: { presence: true },
    forward_to: { presence: true, email: true }
  });
}

export default form(fields, validate)(MailboxForm);
