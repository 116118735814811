import React from "react";
import { connect } from "react-redux";
import h from "h";
import { notifyUnsaved } from "../actions/checkout_actions";

export default function notifyChanges(Component) {
  class Notifier extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.form.isChanged !== this.props.form.isChanged) {
        this.props.notifyUnsaved(nextProps.form.isChanged);
      }
    }

    render() {
      return h(Component, this.props);
    }
  }

  return connect(undefined, mapDispatchToProps)(Notifier);
}

function mapDispatchToProps(dispatch) {
  return {
    notifyUnsaved: step => dispatch(notifyUnsaved(step))
  };
}
