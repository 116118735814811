import React from "react";
import PropTypes from "prop-types";
import { button } from "tags";
import c from "classnames";
import validator from "../util/validator";
import { form, ManagedForm, SubmitButton, fieldType } from "../form";
import VerificationCodeInput from "../../email_verification/VerificationCodeInput";
import VerificationHolder from "../../email_verification/VerificationHolder";
import VerificationError from "../../email_verification/VerificationError";
import VerificationMessage from "../../email_verification/VerificationMessage";
import styles from "./two_factor_email.module.sass";

function TwoFactorEmail(props) {
  const { form, fields, signin, cancelButton, actions } = props;
  const { email } = signin;
  const className = c("appemail", {
    loading: signin.isLoading,
    hasError: signin.error,
  });
  const onSubmit = (values) => {
    actions
      .sendTwoFactorCode(values.twoFactorCode)
      .then(
        (data) => actions.successfulSignin(data),
        // handleTwoFactorRequest has already taken care of this error
        // but we still want to halt
        (_) => null
      );
  };

  const recoveryButton = button(
    ".btn-link.field-help",
    {
      type: "button",
      onClick: actions.twoFactorStartRecovery,
      tabIndex: -1,
    },
    "I can't use my authenticator app"
  );

  const errors = signin.error ? [signin.error] : null;

  return (
    <ManagedForm
      form={form}
      className={styles.two_factor_email_form}
      onSubmit={onSubmit}
    >
      <VerificationError formError={errors} />
      <VerificationHolder className={styles.verification_holder}>
        <VerificationMessage email={email} />
        <VerificationCodeInput updateCode={fields.twoFactorCode.onChange} />
      </VerificationHolder>
      <div className={styles.buttons}>
        <button
          className={c("btn-link", styles.recovery_code_button)}
          onClick={actions.twoFactorStartRecovery}
          type="button"
        >
          Use Recovery Code
        </button>
        <button
          className={c("btn-link", styles.resend_button)}
          onClick={actions.resendSmsRequest}
          type="button"
        >
          Resend Code
        </button>
        <SubmitButton form={form} text="Confirm Code" />
      </div>
    </ManagedForm>
  );
}

TwoFactorEmail.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.shape({
    twoFactorCode: fieldType,
  }).isRequired,
  signin: PropTypes.shape({
    note: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    sendTwoFactorCode: PropTypes.func.isRequired,
  }).isRequired,
  cancelButton: PropTypes.node,
};

const fields = ["twoFactorCode"];

function validate(values) {
  return validator(values, {
    twoFactorCode: { presence: true },
  });
}

export default form(fields, validate)(TwoFactorEmail);
