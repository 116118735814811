import { initSignin } from "2020/header";

export default function() {
  $.get("/user_nav", function(data) {
    if (document.querySelector(".has_new_styles")) {
      $(".nav__link.has-more,.signin__menu").remove()
      const oldItems = document.querySelectorAll(".nav__link.has-more,.signin__menu");

      for(let i = 0; i < oldItems.length; i++) {
        oldItems[i].remove();
      }

      const secondaryNav = document.querySelector(".nav__secondary");
      secondaryNav.innerHTML = secondaryNav.innerHTML + data;
      initSignin();
    } else {
      const [a, b] = $(".user_menu");
      a.remove();
      $(b).replaceWith(data);
    }
  });
}
