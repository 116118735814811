import PropTypes from "prop-types";
import { Component } from "react";
import validator from "../util/validator";
import h from "h";
import c from "classnames";
import { form, ManagedForm } from "../form";
import FormButtons from "./FormButtons";
import NgoAgreement from "./NgoAgreement";
import notifyChanges from "./notifyChanges";

export class TldFormNgo extends Component {
  static PropTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  handleSubmit(values) {
    const r = this.props.onSubmit(values);
    r.catch(errors => {
      this.form.setErrors(errors);
    });
  }

  render() {
    const { form, fields, isLoading } = this.props;
    const className = c({ loading: isLoading });

    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, onSubmit, className }, [
      h(NgoAgreement, { field: fields.agreeTerms }),
      h(FormButtons, this.props)
    ]);
  }
}

function validate(values) {
  return validator(values, {
    agreeTerms: { checked: true }
  });
}

export default form([], validate)(notifyChanges(TldFormNgo));
