import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import toFieldProps from "../form/toFieldProps";
import { div, input } from "tags";

class RadioGroup extends Component {
  render() {
    const { field, options } = this.props;

    const buttons = options.map(({ value, label }) => {
      const checked = value === field.value;
      return h("label", { style: { display: "block" } }, [
        input({
          type: "radio",
          ...toFieldProps(field),
          value,
          checked
        }),
        label
      ]);
    });

    return div(".radio-group", buttons);
  }
}

RadioGroup.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default RadioGroup;
