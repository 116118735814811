import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./total_line.module.sass";

function TotalLine({ label, amount, children, className }) {
  return (
    <div className={c(styles.cart_total_line, className)}>
      {label}
      {amount && <span className={styles.amount}>{amount}</span>}
      {children}
    </div>
  );
}

TotalLine.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default React.memo(TotalLine);
