import { Component } from "react";
import { h } from "h";
import TwoFactorApp from "../TwoFactorApp";

export default class TwoFactorAppForm extends Component {
  render() {
    const { loading, mobile, note, error, actions } = this.props;
    const signin = { note, error, mobile, loading };

    return h(TwoFactorApp, { signin, actions });
  }
}
