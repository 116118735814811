import { Component } from "react";
import { connect } from "react-redux";
import { h } from "h";
import { submitOrder } from "../actions/checkout_actions";
import { braintreeDeviceDataCollected } from "../reducers";
import Section from "../components/Section";
import ConfirmForm from "../components/ConfirmForm";
import { isZcrOrder, isLoading } from "../reducers/checkoutReducer";
import get from "lodash/get";

class ConfirmStep extends Component {
  render() {
    const {
      isLoading,
      cartTotal,
      accountSpend,
      payMethod,
      isZcr,
      onSubmit,
      braintreeDeviceData,
      deviceData
    } = this.props;
    const form = () =>
      h(ConfirmForm, {
        isLoading,
        cartTotal,
        accountSpend,
        payMethod,
        isZcr,
        onSubmit,
        braintreeDeviceData,
        deviceData,
        initialValues: {
          zcrReceiptWanted: isZcr
        }
      });

    return h(Section, {
      ...this.props,
      title: "Order Confirmation",
      viewer: form,
      editor: form
    });
  }
}

function mapStateToProps(state) {
  return {
    cartTotal: state.cart.total,
    accountSpend: state.cart.accountSpend,
    isZcr: isZcrOrder(state.checkout),
    isLoading: isLoading(state.checkout),
    deviceData: braintreeDeviceDataCollected(state),
    payMethod: get(state, "checkout.model.formData.billing.pay_method")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: values => dispatch(submitOrder(values))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStep);
