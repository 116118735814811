import { applyMiddleware } from "redux";
import { browserHistory } from "react-router";
import createNextStepMiddleware from "./nextStepMiddleware";
import remoteActionMiddleware from "./remote_action_middleware";
import ciraMiddleware from "./ciraMiddleware";
import navigateMiddleware from "./navigateMiddleware";
import trackingMiddleware from "./tracking";

export default applyMiddleware(
  remoteActionMiddleware,
  createNextStepMiddleware(browserHistory),
  ciraMiddleware,
  navigateMiddleware,
  trackingMiddleware
);
