import PropTypes from "prop-types"
import h from "h";
import TosForm from "./TosForm";

const onSubmit = (handleSubmit) => () => handleSubmit();

const StandAloneTosForm = ({ handleSubmit }) => {
  return h(".account-form",
    h(TosForm, { onSubmit: onSubmit(handleSubmit) })
  );
}

StandAloneTosForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

export default StandAloneTosForm;
