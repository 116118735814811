import findNextStep from "../store/next_step.js";

export default function createNextStepMiddleware(history) {
  return store => next => action => {
    switch (action.type) {
      case "REDIRECT_TO_NEXT_STEP": {
        const state = store.getState();
        const nextStep = findNextStep(state.checkout);
        history.push(`/checkout/${nextStep}`);
      }
    }
    return next(action);
  };
}
