import c from "classnames";
import { h } from "h";

function Input({
  id,
  className,
  type,
  value,
  onChange,
  onBlur,
  placeholder,
  errorMessage,
  confirmed,
  children
}) {
  const error = !!errorMessage;
  return h("div", [
    h(".text_field", { className: c({ error, confirmed }) }, [
      h("input", {
        id,
        className,
        type,
        value,
        onChange,
        onBlur,
        placeholder,
        autoComplete: "off",
        autoCorrect: "off",
        autoCapitalize: "off",
        spellCheck: "false"
      })
    ]),
    errorMessage && h(".error_msg", errorMessage),
    children
  ]);
}

export default Input;
