import { createStore, compose } from "redux";
import "@/instrument_sentry.js";
import { createReduxEnhancer } from "@sentry/react";

import rootReducer from "../reducers/index";
import middleware from "@/checkout/middleware/index";

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    compose(
      middleware,
      createReduxEnhancer({ attachReduxState: false })
    )
  );
}
