import { browserHistory } from "react-router";

export default () => next => action => {
  if (action.type === "NAVIGATE") {
    const { step, errors } = action;
    browserHistory.push({
      pathname: `/checkout/${step}`,
      state: errors
    });
  }

  return next(action);
};
