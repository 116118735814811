import { h3, span } from "tags";

const MailboxInfo = ({ primaryDomainName, username, forwardTo }) =>
  h3([
    span("Email sent to "),
    email(`${username}@${primaryDomainName}`),
    span(" will be forwarded to "),
    email(`${forwardTo}`),
    span(".")
  ]);

function email(addr) {
  return span(".highlight-email", addr);
}

export default MailboxInfo;
