import PropTypes from "prop-types";
import { Component } from "react";
import validator from "../util/validator";
import getIn from "lodash/fp/get";
import h from "h";
import c from "classnames";
import FormButtons from "./FormButtons";
import TldQuestion from "./TldQuestion";
import { div } from "tags";
import { form, ManagedForm } from "../form";
import notifyChanges from "./notifyChanges";

export class TldFormGeneric extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    stepData: PropTypes.object.isRequired
  };

  handleSubmit(values) {
    const r = this.props.onSubmit(values);
    r.catch(errors => {
      this.form.setErrors(errors);
    });
  }

  render() {
    const { form, fields, isLoading, stepData } = this.props;
    const className = c({ loading: isLoading });

    const questions = stepData.meta.params.map(param => {
      const field = getIn(param.key, fields);
      return div(".row", h(TldQuestion, { param, field }));
    });
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", questions),
      h(FormButtons, this.props)
    ]);
  }
}

function validate(values, props) {
  const params = props.stepData.meta.params;
  const requiredFields = params
    .filter(param => param.required)
    .map(param => param.key)
    .map(key => key.replace(/.*\./, ""));

  let rules = {};
  for (const name of requiredFields) {
    rules[name] = { presence: true };
  }

  return validator(values, rules);
}

export default form([], validate)(notifyChanges(TldFormGeneric));
