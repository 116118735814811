import PropTypes from "prop-types";
import { Component } from "react";
import { div, a } from "tags";
import c from "classnames";
import h from "h";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  TextField,
  fieldType
} from "../form";

export class TwoFactorRecovery extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      recoveryCode: fieldType
    }).isRequired,
    signin: PropTypes.shape({
      error: PropTypes.string,
      loading: PropTypes.bool
    }),
    actions: PropTypes.shape({
      twoFactorRecoveryRequest: PropTypes.func.isRequired
    }).isRequired,
    cancelButton: PropTypes.node
  };

  handleSubmit(values) {
    const { actions } = this.props;
    actions.twoFactorRecoveryRequest(values.recoveryCode).then(data => {
      if (data.status === "completed") {
        return actions.successfulSignin(data);
      }
    });
  }

  render() {
    const { form, fields, signin, cancelButton } = this.props;
    const className = c("recovery", {
      loading: signin.isLoading,
      hasError: signin.error
    });
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, className, onSubmit }, [
      div(".fieldgroup", [
        h(FormError, { text: signin.error }),
        div(".row.note", "Provide your recovery code to access your account"),
        div(".row", [
          h(TextField, {
            width: 12,
            label: "Recovery Code",
            field: fields.recoveryCode,
            autoFocus: true
          })
        ]),
        div(".row.note", [
          "Don't have your recovery code? ",
          a(
            { href: "https://help.hover.com/", target: "_blank" },
            "Contact Hover Support"
          )
        ])
      ]),
      div(".fieldgroup.buttons", [
        h(SubmitButton, { form, text: "Continue" }),
        cancelButton
      ])
    ]);
  }
}

const fields = ["recoveryCode"];

function validate(values) {
  return validator(values, {
    recoveryCode: { presence: true }
  });
}

export default form(fields, validate)(TwoFactorRecovery);
