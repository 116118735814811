import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import c from "classnames";
import { h } from "h";
import { div } from "tags";
import * as signinActions from "../actions/signinActions";

import SigninForm from "../components/SigninForm";
import SigninEmailForm from "../components/SigninEmailForm";
import SigninTokenForm from "../components/SigninTokenForm";
import UserSelect from "../components/UserSelect";
import ForgotUsername from "../components/ForgotUsername";
import ForgotPassword from "../components/ForgotPassword";
import TwoFactorSms from "../components/TwoFactorSms";
import TwoFactorApp from "../components/TwoFactorApp";
import TwoFactorEmail from "checkout/components/TwoFactorEmail";
import TwoFactorRecovery from "../components/TwoFactorRecovery";
import ShowEmailConfirmation from "../components/ShowEmailConfirmation";
import EditEmailConfirmation from "../components/EditEmailConfirmation";
import SigninVerification from "../components/SigninVerification";

import "../styles/signin.scss";

class SigninMain extends Component {
  render() {
    const className = this.className();
    return div(".signin", { className: c("signin", className)}, [this.header(), this.form(), this.footer()]);
  }

  className() {
    switch (this.props.signin.step) {
      case "ask_verification_code":
        return "signin-verification";
      case "ask_2fa_email":
        return "two-factor-email"
      default:
        return null;
    }
  }

  footer() {
    switch (this.props.signin.step) {
      case "ask_user_select":
      case "ask_2fa_sms":
      case "ask_2fa_app":
      case "ask_2fa_email":
      case "ask_email_confirmation":
      case "edit_email":
      case "ask_verification_code":
        return null;
      default:
        return h(
          ".footer",
          h("p", [
            h("span", "Don't have an account? "),
            h("a", { href: "/signup" }, "Sign up."),
          ])
        );
    }
  }

  header() {
    switch (this.props.signin.step) {
      case "ask_email_confirmation":
      case "edit_email":
        return null;
      case "ask_verification_code":
        return h(".header", [h("h1", "Confirm your email address")]);
      default:
        return h(".header", [h("h1", "Sign in")]);
    }
  }

  form() {
    const { signin, actions } = this.props;
    const step = signin.step;
    switch (step) {
      case "ask_username_password": {
        return h(SigninForm, {
          signin,
          actions,
          recaptchaKey: signin.recaptchaKey,
          recaptchaSigninEnabled: signin.recaptchaSigninEnabled,
          magicSigninEnabled: signin.magicSigninEnabled,
        });
      }

      case "ask_email_confirmation": {
        const { email, url } = signin.verification;
        return h(ShowEmailConfirmation, { actions, email, url });
      }

      case "edit_email": {
        const { email, url } = signin.verification;
        return h(EditEmailConfirmation, { actions, email, url });
      }

      case "ask_verification_code": {
        return h(SigninVerification, { ...signin.verification, actions });
      }

      case "ask_email": {
        const initialValues = {
          email: signin.email,
        };
        return h(SigninEmailForm, { actions, signin, step, initialValues });
      }

      case "ask_user_select":
        return h(UserSelect, { actions, users: signin.users });

      case "ask_token":
        return h(SigninTokenForm, { actions, signin, step });

      case "forgot_username":
        return h(ForgotUsername, { signin, actions });

      case "forgot_password":
        return h(ForgotPassword, { signin, actions });

      case "ask_2fa_sms":
        return h(TwoFactorSms, { signin, actions });

      case "ask_2fa_app":
        return h(TwoFactorApp, { signin, actions });

      case "ask_2fa_email":
        return h(TwoFactorEmail, { signin, actions });

      case "recovery":
        return h(TwoFactorRecovery, { signin, actions });

      default:
        throw new Error("No such step: " + step);
    }
  }
}

SigninMain.propTypes = {
  signin: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { signin: state.signin };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...signinActions,
    successfulSignin: signinActions.mainSigninSuccess,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninMain);
