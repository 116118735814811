import Immutable from "seamless-immutable";

export default function nextStep(checkoutState) {
  const model = checkoutState.model;
  const steps = model.steps || Immutable([]);

  const pendingStep = steps.find(s => s.status === "pending");

  if (pendingStep) {
    return pendingStep.name;
  } else {
    throw new Error("No pending steps found");
  }
}
