import PropTypes from "prop-types";
import { Component } from "react";
import validator from "../util/validator";
import h from "h";
import c from "classnames";
import { div } from "tags";
import { form, ManagedForm, SelectField, TextField } from "../form";
import FormButtons from "./FormButtons";
import notifyChanges from "./notifyChanges";
import { disclaimer } from "checkout/util/eu";
import { countryNameDictionary } from "common/countries";

function orgNameRequired(euRegistrationType) {
  return euRegistrationType === "business";
}

function citizenshipRequired(euRegistrationType, ownerCountry, ownerCountries) {
  return euRegistrationType === "individual" && !ownerCountries.includes(ownerCountry);
}

export class TldFormEu extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    stepData: PropTypes.object.isRequired
  };

  handleSubmit(values) {
    /*
      We want to be able to hang on to information in case the user switches
      back and forth between business and individual in the checkout process,
      but we don't want to hang on to the country_of_citzenship if they switch \
      to business.
    */
    const businessKeys = ["eu_registration_type", "ext", "org_name", "owner_country"];
    const individualKeys = [...businessKeys, "country_of_citizenship"];
    const keys = values.eu_registration_type === "individual" ? individualKeys : businessKeys;
    const fields = Object.entries(values).reduce((acc, [key, value]) => {
      if (keys.includes(key)) {
        return Object.assign({}, acc, { [key]: value })
      } else {
        return acc;
      }
    }, {});
    const r = this.props.onSubmit(fields);
    r.catch(errors => {
      this.form.setErrors(errors);
    });
  }

  showOrgName() {
    return orgNameRequired(this.props.fields.eu_registration_type.value);
  }

  showCountryOfCitizenship() {
    return citizenshipRequired(
      this.props.fields.eu_registration_type.value,
      this.props.initialValues.owner_country,
      this.props.stepData.meta.restrictions.owner_countries
    );
  }

  render() {
    const { form, fields, isLoading, stepData } = this.props;
    const className = c({ loading: isLoading });

    const ownerCountries = stepData.meta.restrictions.owner_countries;

    const onSubmit = values => this.handleSubmit(values);

    const opts = [
      {
        label: "Individual",
        value: "individual"
      },
      {
        label: "Business",
        value: "business"
      }
    ];

    const countryOptions = ownerCountries.map((country) => ({
      value: country,
      label: countryNameDictionary[country]
    }));

    return h(ManagedForm, { form, onSubmit, className }, [
      div(".fieldgroup", [
        div(".row", [
          h(SelectField, {
            width: 12,
            field: fields.eu_registration_type,
            options: opts,
            label: "EU Registration Type"
          })
        ]),
        this.showOrgName() && div(".row", [
          h(TextField, {
            width: 12,
            field: fields.org_name,
            label: "Organization"
          })
        ]),
        this.showCountryOfCitizenship() && div(".row", [
          h(SelectField, {
            width: 12,
            field: fields.country_of_citizenship,
            options: countryOptions,
            label: "Country of Citizenship"
          })
        ])
      ]),
      div(".fieldgroup", h("p", disclaimer)),

      h(FormButtons, this.props)
    ]);
  }
}

function validate(values, ownProps) {
  const euRegistrationType = values.eu_registration_type;
  const ownerCountry = ownProps.initialValues.owner_country;
  const ownerCountries = ownProps.stepData.meta.restrictions.owner_countries;

  return validator(values, {
    eu_registration_type: { presence: true },
    org_name: { presence: orgNameRequired(euRegistrationType) },
    country_of_citizenship: { presence: citizenshipRequired(euRegistrationType, ownerCountry, ownerCountries) }
  });
}

const fields = ["eu_registration_type", "org_name", "country_of_citizenship"];

export default form(fields, validate)(notifyChanges(TldFormEu));
