import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import h from "h";
import { h1, div } from "tags";
import NewPasswordForm from "../components/passwordReset/NewPasswordForm";
import TwoFactorAppForm from "../components/passwordReset/TwoFactorAppForm";
import TwoFactorSmsForm from "../components/passwordReset/TwoFactorSmsForm";
import TwoFactorRecoveryForm from "../components/passwordReset/TwoFactorRecoveryForm";
import BadToken from "../components/passwordReset/BadToken";
import { xhrPost } from "common/xhr";

class PasswordReset extends Component {
  static propTypes = {
    token: PropTypes.string // optional
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: props.token ? "new_password" : "bad_token",
      note: null,
      error: null
    };
  }

  handleNewPassword(values) {
    const { token } = this.props;
    const { password } = values;

    this.withLoading(() => {
      return xhrPost(`/password_reset/${token}/set_password`, { password }).then(
        response => {
          if (response.status === "completed") {
            document.location = response.url;
          } else if (response.status === "need_2fa" && response.type === "sms") {
            this.twoFactorSms(response.mobile);
          } else if (response.status === "need_2fa") {
            this.twoFactorApp();
          } else {
            this.setError(response.error);
          }
        }
      );
    });
  }

  handleTwoFactorCodeEntered(code) {
    const { token } = this.props;
    return this.withLoading(() => {
      return xhrPost(`/password_reset/${token}/auth2`, { code, }).then(response => {
        if (response.error) {
          this.setError(response.error);
        }

        return Promise.resolve(response);
      });
    });
  }

  handleSignedIn(response) {
    if (response && response.url) {
      document.location = response.url;
    }
  }

  handleResendSms() {
    const { token } = this.props;
    this.withLoading(() => {
      return xhrPost(`/password_reset/${token}/resend_code`).then(response => {
        if (response.succeeded) {
          this.setNote(response.note);
        } else {
          this.setError(response.error || "Action failed");
        }
      });
    });
  }

  handleRecoveryCodeEntered(code) {
    const { token } = this.props;
    return this.withLoading(() => {
      return xhrPost(`/password_reset/${token}/recovery`, { recovery_code: code }).then(response => {
        if (response.error) {
          this.setError(response.error);
        }

        return Promise.resolve(response);
      });
    });
  }

  handleRecovery() {
    this.setPage("2fa_recovery");
  }

  setError(error) {
    this.setState({ note: null, error });
  }

  setNote(note) {
    this.setState({ note, error: null });
  }

  setPage(page) {
    this.setState({ page });
  }

  withLoading(action) {
    this.setState({ loading: true });
    return action().finally(() => this.setState({ loading: false }));
  }

  twoFactorSms(mobile) {
    this.setState({ page: "2fa_sms", mobile });
  }

  twoFactorApp() {
    this.setState({ page: "2fa_app" });
  }

  twoFactorRecovery() {
    this.setState({ page: "2fa_recovery" });
  }

  render() {
    return div(".signin", [h1("Reset your password."), this.form()]);
  }

  form() {
    const { token } = this.props;
    const { loading, mobile, note, error } = this.state;
    const actions = {
      setNewPassword: password => this.handleNewPassword(password),
      sendTwoFactorCode: code => this.handleTwoFactorCodeEntered(code),
      successfulSignin: data => this.handleSignedIn(data),
      resendSmsRequest: () => this.handleResendSms(),
      twoFactorStartRecovery: () => this.handleRecovery(),
      twoFactorRecoveryRequest: code => this.handleRecoveryCodeEntered(code)
    };
    const props = {
      token,
      loading,
      mobile,
      note,
      error,
      actions
    };

    switch (this.state.page) {
      case "bad_token":
        return h(BadToken, props);

      case "new_password":
        return h(NewPasswordForm, props);

      case "2fa_app":
        return h(TwoFactorAppForm, props);

      case "2fa_sms":
        return h(TwoFactorSmsForm, props);

      case "2fa_recovery":
        return h(TwoFactorRecoveryForm, props);

      default:
        throw new Error(`unknown page ${this.state.page}`);
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.signin.token,
  };
}

export default connect(mapStateToProps)(PasswordReset);
