import React, { Fragment } from "react";
import { fasIcon } from "h";

function MagicSigninLink(props) {
  return (
    <div className="magic-signin-link">
      <Button step={props.step} onClick={props.onClick} />
    </div>
  );
}

function Button({ onClick, step }) {
  switch (step) {
    case "ask_username_password":
      return (
        <button className="btn-link" onClick={() => onClick("ask_email")}>
          {fasIcon("sparkles")}
          <span>or Sign in With Magic Token</span>
        </button>
      );

    case "ask_email":
      return (
        <button
          className="btn-link"
          onClick={() => onClick("ask_username_password")}
        >
          {fasIcon("key")}
          <span>or Sign in With Username & Password</span>
        </button>
      );
  }
}

export default MagicSigninLink;
