import { Component } from "react";
import { connect } from "react-redux";
import h from "h";

import {
  submitRegistrationData,
  redirectToNextStep
} from "../actions/checkout_actions";
import { isLoading } from "../reducers/checkoutReducer";

import Section from "../components/Section";
import RegistrationInfo from "../components/RegistrationInfo";
import RegistrationForm from "../components/RegistrationForm";
import { getEuRegType, onlyEu } from "checkout/util/eu";

class RegistrationStep extends Component {
  render() {
    const {
      initialValues,
      registration,
      stepData,
      isLoading,
      onSubmit,
      onCancel,
      euRegType,
      onlyEuDomains,
      orgNameRequired,
      formError
    } = this.props;

    return h(Section, {
      ...this.props,
      title: "Registration Information",
      viewer: () => h(RegistrationInfo, { orgNameRequired, ...this.props.initialValues }),
      editor: () =>
        h(RegistrationForm, {
          initialValues,
          registration,
          onSubmit,
          onCancel,
          isLoading,
          status: stepData.status,
          euRegType,
          onlyEuDomains,
          orgNameRequired,
          formError
        })
    });
  }
}

export function mapStateToProps(state) {
  const initialValues = state.checkout.model.formData.registration;
  const euRegType = getEuRegType(state.checkout);
  const onlyEuDomains = onlyEu(state.cart);
  const orgNameRequired = !onlyEuDomains || euRegType === "business";


  return {
    initialValues,
    isLoading: isLoading(state.checkout),
    euRegType,
    onlyEuDomains,
    orgNameRequired
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: values => dispatch(submitRegistrationData(values)),
    onCancel: () => dispatch(redirectToNextStep())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStep);
