import PropTypes from "prop-types";
import { Component } from "react";
import validator from "../util/validator";
import h from "h";
import c from "classnames";
import { div } from "tags";
import { form, ManagedForm } from "../form";
import FormButtons from "./FormButtons";
import TldQuestion from "./TldQuestion";
import CiraAgreement from "./CiraAgreement";
import notifyChanges from "./notifyChanges";
import CiraLegalTypeWarning from "./CiraLegalTypeWarning";

export class TldFormCira extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    stepData: PropTypes.object.isRequired,
    cira: PropTypes.shape({
      terms: PropTypes.string
    }).isRequired
  };

  handleSubmit(values) {
    const r = this.props.onSubmit(values);
    r.catch(errors => {
      this.form.setErrors(errors);
    });
  }

  render() {
    const { form, fields, isLoading, stepData, cira } = this.props;
    const className = c({ loading: isLoading });

    const [regNameParam, legalTypeParam] = stepData.meta.params;
    // const questions = stepData.meta.params.reverse().map(param => {
    //   const field = getIn(param.key, fields);
    //   return div(".row", h(TldQuestion, { param, field }));
    // });
    const onSubmit = values => this.handleSubmit(values);

    return h(ManagedForm, { form, onSubmit, className }, [
      div(".fieldgroup", [
        div(".row", [
          h(TldQuestion, {
            param: legalTypeParam,
            field: fields.legal_type
          })
        ]),
        div(".row", [
          h(TldQuestion, {
            param: regNameParam,
            field: fields.registrant_name
          })
        ]),
        h(CiraLegalTypeWarning, {
          registrant_name: fields.registrant_name.value,
          legal_type: fields.legal_type.value
        })
      ]),
      h(CiraAgreement, {
        content: cira.terms,
        field: fields.agreeTerms
      }),
      h(FormButtons, this.props)
    ]);
  }
}

function validate(values) {
  return validator(values, {
    legal_type: { presence: true },
    registrant_name: {
      presence: true,
      validCiraName: { legalType: values.legal_type }
    },
    agreeTerms: { checked: true }
  });
}

export default form([], validate)(notifyChanges(TldFormCira));
