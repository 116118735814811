import { h, titleize } from "h";
import Summary from "./Summary";
import { disclaimer } from "checkout/util/eu";

const businessItems = ["Registration Type", "Organization"];
const individualItems = ["Registration Type", "Country of Citizenship"]

function TldEuSummary({ items }) {
  const regType = items.find((item) => item[0] === "Registration Type")[1];
  const displayLabels = regType === "business" ? businessItems : individualItems;
  const displayItems = items.filter((item) => {
    const [label, value, required] = item;
    if (!displayLabels.includes(label) || (!required && !value)) {
      return false;
    } else {
      return true;
    }

  });
  return h("div", [
    h(Summary, { items: displayItems.map(item => item.map( val => titleize(val))) }),
    h("p.disclaimer", disclaimer)
  ]);
}

export default TldEuSummary;
