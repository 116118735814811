import ReactDOM from "react-dom";
import h from "h";
import ViewCartApp from "./view_cart/app";

function initViewCart(node, props) {
  document.addEventListener("DOMContentLoaded", function() {
    ReactDOM.render(h(ViewCartApp, props), node);
  });
}

export default initViewCart;
