// import { PropTypes } from "react";
import { dl, dt, dd } from "tags";

function Summary({ items }) {
  let elts = [];
  items.forEach(([label, value, _key]) => {
    elts.push(dt({ key: `t${label}` }, label));
    elts.push(dd({ key: `d${label}` }, value));
  });
  return dl(elts);
}
// Summary.propTypes = {
//   // XXX - why not [{ label, value }]?
//   items: PropTypes.arrayOf(
//     PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
//   ).isRequired
// };

export default Summary;
