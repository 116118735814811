import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import { div, button } from "tags";
import { SubmitButton } from "../form";

export default class FormButtons extends Component {
  static propTypes = {
    status: PropTypes.oneOf(["pending", "completed"]),
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.shape({
      isReady: PropTypes.bool.isRequired
    }).isRequired
  };
  render() {
    const { status, onCancel, form } = this.props;
    const className = this.props.className || "fieldgroup buttons";
    return div({ className }, buttons(status, onCancel, form));
  }
}

function buttons(status, onCancel, form) {
  const editMode = status === "completed";

  if (editMode) {
    return [cancelButton(onCancel), h(SubmitButton, { form, text: "Save Changes" })];
  } else {
    return h(SubmitButton, { form, text: "Continue" });
  }
}

function cancelButton(onClick) {
  return button(".btn-link.form_cancel", { type: "button", onClick }, "Cancel");
}
