import { connect } from "react-redux";
import { submitTldData, redirectToNextStep } from "../actions/checkout_actions";

import { h } from "h";
import Section from "../components/Section";
import TldInfo from "../components/TldInfo";
import TldForm from "../components/TldForm";
import { onlyEu } from "checkout/util/eu";

function TldStep({ ext, ...props }) {
  return h(Section, {
    ...props,
    title: `TLD Info for .${ext}`,
    viewer: () =>
      h(TldInfo, {
        params: props.stepData.meta.params,
        values: props.initialValues,
        ext
      }),
    editor: () => h(TldForm, { ...props, status: props.stepData.status })
  });
}

function tldSubmit(values) {
  const { ext, ...data } = values;
  return submitTldData({
    tld: { name: ext, data }
  });
}

function dynamicForm(component) {
  return props => {
    const { stepName, stepData } = props;

    const ext = stepName.replace("tld_", "").toUpperCase();
    const fieldNames = [
      "ext",
      ...stepData.meta.params.map(p => p.key),
      ...extraCiraFields(props.stepName)
    ];
    const initialValues = { ext, ...stepData.meta.values };

    return h(component, {
      ...props,
      fieldNames,
      initialValues,
      ext
    });
  };
}

function extraCiraFields(stepName) {
  if (stepName === "tld_ca") {
    return ["agreeTerms"];
  } else {
    return [];
  }
}

function mapStateToProps(state) {
  return {
    cira: state.cira,
    onlyEu: onlyEu(state.cart)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: data => dispatch(tldSubmit(data)),
    onCancel: () => dispatch(redirectToNextStep())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(dynamicForm(TldStep));
