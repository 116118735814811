import PropTypes from "prop-types";
import { Component } from "react";
import h from "h";
import c from "classnames";
import { div, button } from "tags";
import {
  form,
  ManagedForm,
  FormError,
  TextField,
  PhoneField,
  SelectField,
  ComboField,
  CheckboxField,
  SubmitButton
} from "../form";
import ErrorContainer from "../form/ErrorContainer";
import RadioGroup from "./RadioGroup";
import PaypalButton from "./PaypalButton";
import { BraintreeFields, isValid as braintreeIsValid } from "../braintree";
import { submit as submitToBraintree } from "../braintree";
import validator from "../util/validator";
import notifyChanges from "./notifyChanges";
import Summary from "./Summary";
import { fmtCreditCard } from "./format";

import { countries } from "common/countries";
import { stateOptions, fixState } from "../wrappers/withCountries";
import Crossfade from "./Crossfade";
import { CheckoutCardAgreement } from "./CardAgreement";

import "../styles/apple_pay.css";

export class BillingForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    form: PropTypes.shape({
      isChanged: PropTypes.bool.isRequired
    }).isRequired,
    billing: PropTypes.shape({
      payment_on_file: PropTypes.object,
      paymentOptions: PropTypes.array.isRequired
    }).isRequired,
    braintree: PropTypes.shape({
      validity: PropTypes.shape({
        number: PropTypes.string.isRequired,
        expirationDate: PropTypes.string.isRequired,
        cvv: PropTypes.string.isRequired
      }) // not required
    }),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(["completed", "pending"]).isRequired,
    modal: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      formValues: {},
      showTerms: false
    };
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleSubmit(values) {
    this.props.onSubmit(values).catch(errors => {
      this.props.form.notifySubmitErrors(errors);
    });
  }

  updateFormValues(formValues) {
    this.setState({ formValues, showTerms: true });
  }

  brainTreeSuccessHandler(values) {
    const { braintree } = this.props;
    if (requiresCardAgreement(values.pay_method)) {
      return braintreeData =>
        this.updateFormValues({
          ...values,
          nonce: braintreeData.nonce,
          details: braintreeData.details,
          device_data: braintree.deviceData
        });
    } else {
      return braintreeData =>
        this.handleSubmit({
          ...values,
          nonce: braintreeData.nonce,
          details: braintreeData.details,
          device_data: braintree.deviceData
        });
    }
  }

  renderCardForm() {
    if (this.state.showTerms) {
      const onSubmit = values => {
        this.handleSubmit({
          ...this.state.formValues,
          card_agreement: true
        });
      };
      const onCancel = () => this.handleCancel();
      return {
        cardAgreement: h(CheckoutCardAgreement, { onCancel, onSubmit })
      };
    } else {
      const {
        fields,
        form,
        currentValues,
        billing,
        braintree,
        actions
      } = this.props;
      const { status, isLoading } = this.props;
      const { payment_on_file } = billing;

      const wantSaveAsDefault = form.isChanged && billing.payment_on_file;
      const payMethod = fields.pay_method.value;
      const collectingCreditCard = payMethod === "new";
      const className = c({ loading: isLoading });

      const onSubmit = values =>
        submitToBraintree().then(this.brainTreeSuccessHandler(values));

      const updatedForm = collectingCreditCard
        ? updateReadyState(form, braintree)
        : form;
      const braintreeIsActive =
        braintree.clientIsValid &&
        braintree.needHostedFields &&
        fields.pay_method.value === "new";

      const submitText = requiresCardAgreement(fields.pay_method.value)
        ? "Continue"
        : "Save Changes";

      const cardForm = h(
        BraintreeFields,
        { key: "btf", actions, active: braintreeIsActive },
        [
          h(
            ManagedForm,
            {
              form,
              currentValues,
              className,
              onSubmit,
              id: "braintree-form"
            },
            [
              div(".fieldgroup", [
                h(RadioGroup, {
                  field: fields.pay_method,
                  options: billing.paymentOptions
                })
              ]),
              div(".fieldgroup", [
                form.formError && h(FormError, { text: form.formError }),
                h(PaymentFields, {
                  fields,
                  payment_on_file,
                  braintree,
                  actions
                }),
                payMethod !== "paypal" && h(AddressFields, { fields })
              ]),
              !this.props.modal &&
                div("", [
                  div(".fieldgroup.buttons", [
                    button(
                      ".btn-link.form_cancel",
                      { type: "button", onClick: () => this.handleCancel() },
                      "Cancel"
                    ),
                    h(SubmitButton, { form, text: submitText })
                  ]),
                  wantSaveAsDefault &&
                    div(".row", [
                      h(CheckboxField, {
                        width: 12,
                        label: "Use this for future purchases",
                        field: fields.save_as_default
                      })
                    ])
                ])
            ]
          )
        ]
      );
      return {
        cardForm
      };
    }
  }

  render() {
    if (this.props.fields.pay_method.value === "paypal") {
      return h(PayPalBilling, this.props);
    }
    if (this.props.fields.pay_method.value === "apple_pay") {
      return h(ApplePayBilling, this.props);
    }

    const { cardAgreement, cardForm } = this.renderCardForm();

    const className = c({ agree: cardAgreement });

    return h(
      ".cc-form",
      { className },
      h(Crossfade, {
        child1: cardForm,
        child2: cardAgreement,
        mainStyle: { padding: "0" }
      })
    );
  }
}

const PaymentFields = ({ fields, payment_on_file, braintree, actions }) => {
  switch (fields.pay_method.value) {
    case "new":
      return h(NewCreditCardFields, { fields, braintree, actions });
    case "creditcard": {
      const existingPayment = payment_on_file;
      const changeCard = () => fields.pay_method.onChange("new");
      return h(ExistingCreditCardFields, { existingPayment, changeCard });
    }
    case "paypal":
    case "paypal_on_file":
    case "apple_pay":
    case "apple_pay_on_file":
      return div();
    default:
      throw new Error("No such pay method: " + fields.pay_method.value);
  }
};

const NewCreditCardFields = ({ fields, braintree }) =>
  div(".new_cc", [
    h(".new_credit_card", [
      div(".row", [
        h(TextField, {
          width: 6,
          label: "First Name",
          field: fields.first_name,
          autoComplete: "billing cc-given-name"
        }),
        h(TextField, {
          width: 6,
          label: "Last Name",
          field: fields.last_name,
          autoComplete: "billing cc-family-name"
        })
      ]),
      div(".row", h(BraintreeCreditCardInput, { braintree, field: fields.number })),
      div(".row", [
        h(BraintreeExpireDateInput, {
          braintree,
          field: fields.expirationDate
        }),
        h(BraintreeCVVInput, { braintree, field: fields.cvv })
      ])
    ])
  ]);

const BraintreeCreditCardInput = ({ braintree, field }) => {
  const { cardType, validity } = braintree;
  const className = c(
    "ccicons",
    "form-input",
    cardType && `cc-type-${cardType}`,
    validity.number === "valid" && "cc-valid"
  );

  const conClass = c("field", "grid12");

  const container = h("div#cc-number", {
    style: { height: 40 },
    className
  });
  return h(ErrorContainer, { className: conClass, field, showReady: false }, [
    h("label", [div(".caption", "Card Number"), container])
  ]);
};

const Validity = ({ grade, children }) => {
  const style = { position: "relative" };
  const className = c(
    "error-container",
    "form-input",
    grade === "valid" && "field-ready"
  );

  return div({ style, className }, children);
};

const BraintreeExpireDateInput = ({ braintree, field }) => {
  const { validity } = braintree;

  const container = h(Validity, { grade: validity.expirationDate }, [
    h("div#cc-expiry", { style: { height: 40 } })
  ]);
  return div(".field.grid6", [
    h(ErrorContainer, { field, showReady: false }, [
      h("label", [div(".caption", "Expiry"), container])
    ])
  ]);
};

const BraintreeCVVInput = ({ braintree, field }) => {
  const { validity } = braintree;

  const container = h(Validity, { grade: validity.cvv }, [
    h("div#cc-cvv", { style: { height: 40 } })
  ]);
  return div(".field.grid6", [
    h(ErrorContainer, { field, showReady: false }, [
      h("label", [div(".caption", "CVV"), container])
    ])
  ]);
};

const ExistingCreditCardFields = ({ existingPayment, changeCard }) => {
  if (!existingPayment) {
    existingPayment = { name_on_card: "bob" };
  }
  const changeCardButton = h(
    "button.btn.btn-default",
    {
      type: "button",
      onClick: changeCard
    },
    "Change Card"
  );
  return div(".new_credit_card", [
    h(Summary, {
      items: [
        ["Name", existingPayment.name_on_card],
        ["Payment", fmtCreditCard(existingPayment)],
        [changeCardButton, "\xa0"] // The \xa0 is a nonbreaking space for Cucumber.
      ]
    })
  ]);
};

const AddressFields = ({ fields }) => {
  const countryVal = fields.country.value;
  const states = stateOptions(countryVal);
  const state = fixState(fields.state, states.options);

  return div(".address_fields", [
    div(".row", [
      h(TextField, {
        width: 12,
        label: "Address 1",
        field: fields.address1,
        autoComplete: "billing address-line1"
      })
    ]),
    div(".row", [
      h(TextField, {
        width: 12,
        label: "Address 2 (optional)",
        field: fields.address2,
        autoComplete: "billing address-line2"
      })
    ]),
    div(".row", [
      h(SelectField, {
        width: 6,
        label: "Country",
        field: fields.country,
        options: countries,
        autoComplete: "billing country"
      }),
      h(ComboField, {
        width: 6,
        label: states.label,
        field: state,
        options: states.options,
        autoComplete: "billing address-level1"
      })
    ]),
    div(".row", [
      h(TextField, {
        width: 6,
        label: "City",
        field: fields.city,
        autoComplete: "billing address-level2"
      }),
      h(TextField, {
        width: 6,
        label: states.zip,
        field: fields.zip,
        autoComplete: "billing postal-code"
      })
    ]),
    div(".row", [
      h(PhoneField, {
        width: 6,
        label: "Phone Number",
        field: fields.phone,
        autoComplete: "billing tel"
      }),
      div(".grid6", "")
    ])
  ]);
};

function updateReadyState(form, braintree) {
  // If braintree is valid, the form must have been changed.
  return { isReady: braintreeIsValid(braintree) && form.isValid };
}

const requiresCardAgreement = payMethod => payMethod === "new";

const fields = [
  "pay_method",
  "save_as_default",
  "number",
  "expirationDate",
  "cvv",
  "first_name",
  "last_name",
  "address1",
  "address2",
  "country",
  "state",
  "city",
  "zip",
  "phone"
];

function validate(values) {
  if (values.pay_method === "paypal" || values.pay_method === "paypal_on_file") {
    return {};
  }
  const stateRequired = ["US", "CA", "AU", "IT"].includes(values.country);
  return validator(values, {
    ...paymentSourceRules(values),
    address1: { presence: true },
    country: { presence: true },
    city: { presence: true },
    state: { presence: stateRequired },
    zip: { presence: true, postalCode: true },
    phone: { presence: true, length: { min: 7 } }
  });
}

function paymentSourceRules(values) {
  switch (values.pay_method) {
    case "new":
      return {
        first_name: {
          presence: true,
          length: {
            minimum: 2
          }
        },
        last_name: {
          presence: true,
          length: {
            minimum: 2
          }
        },
        number: {
          presence: true,
          braintreeProxy: true
        },
        expirationDate: {
          presence: true,
          braintreeProxy: true
        },
        cvv: {
          presence: true,
          braintreeProxy: true
        }
      };

    default:
      return {};
  }
}

function PayPalBilling(props) {
  const { form, fields, braintree, actions } = props;
  const saveAsDefault = fields.save_as_default.value;
  const disabled = braintree.paypalIsOpen || !braintree.paypalIsReady;

  return BoringBilling(
    props,
    h(PaypalButton, {
      disabled,
      logo: braintree.paypalLogo,
      onClick: () => {
        actions
          .openPaypal({ saveAsDefault })
          .catch(errors => form.notifySubmitErrors(errors));
      }
    })
  );
}

function applePayAction({ fields, cartTotal, actions }) {
  const saveAsDefault = fields.save_as_default.value;

  if (cartTotal) {
    return () =>
      actions.openApplePay({
        amount: cartTotal.replace("$", ""),
        label: "Hover.com",
        saveAsDefault
      });
  } else {
    return () =>
      actions.openApplePay({
        amount: "0.01",
        label: "Hover.com (won't be charged)",
        saveAsDefault
      });
  }
}

function ApplePayBilling(props) {
  const { fields, cartTotal, actions } = props;
  const saveAsDefault = fields.save_as_default.value;
  const openApplePay = applePayAction(props);

  return BoringBilling(
    props,
    button(
      "#apple-pay-button.apple-pay-button.visible",
      { type: "button", onClick: openApplePay },
      ""
    )
  );
}

function BoringBilling(props, paymentButton) {
  const { form, fields, currentValues, billing, onCancel, modal } = props;
  const wantSaveAsDefault = billing.payment_on_file;
  const onSubmit = () => {};

  return div(
    ".braintree",
    h(ManagedForm, { form, currentValues, onSubmit, submit: "Submit" }, [
      div(
        ".fieldgroup",
        h(RadioGroup, {
          field: fields.pay_method,
          options: billing.paymentOptions
        })
      ),
      div({ style: { marginTop: 20 } }, [
        form.formError && h(FormError, { text: form.formError }),
        div(".buttons", { style: { height: 50 } }, [
          paymentButton,
          !modal &&
            button(
              ".btn.btn-link.form_cancel",
              {
                type: "button",
                onClick: onCancel
              },
              "Cancel"
            )
        ])
      ]),
      !modal &&
        wantSaveAsDefault &&
        div(".row", [
          h(CheckboxField, {
            width: 12,
            label: "Use this for future purchases",
            field: fields.save_as_default
          })
        ])
    ])
  );
}

export default form(fields, validate)(notifyChanges(BillingForm));
