import PropTypes from "prop-types";
import { Component } from "react";
import { div, button, span } from "tags";
import c from "classnames";
import h from "h";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  TextField,
  fieldType
} from "../form";

export class TwoFactorSms extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      twoFactorCode: fieldType
    }).isRequired,
    signin: PropTypes.shape({
      note: PropTypes.string,
      error: PropTypes.string,
      mobile: PropTypes.string.isRequired,
      loading: PropTypes.bool
    }),
    actions: PropTypes.shape({
      sendTwoFactorCode: PropTypes.func.isRequired,
      successfulSignin: PropTypes.func.isRequired,
      resendSmsRequest: PropTypes.func.isRequired,
      twoFactorStartRecovery: PropTypes.func.isRequired
    }).isRequired,
    cancelButton: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.state = { resendConfirm: null };
  }

  handleSubmit(values) {
    const { actions } = this.props;
    actions
      .sendTwoFactorCode(values.twoFactorCode)
      .then(data => actions.successfulSignin(data));
  }

  resendState() {
    const { signin } = this.props;
    const divProps = {
      style: {
        float: "right"
      }
    };

    return div([
      this.resendActions(),
      signin.note && div(".signin-note", divProps, signin.note)
    ]);
  }

  askResend() {
    this.setState({ resendConfirm: "asking" });
  }

  cancelResend() {
    this.setState({ resendConfirm: null });
  }

  resendActions() {
    const { signin, actions } = this.props;
    const { mobile } = signin;

    if (this.state.resendConfirm === "asking") {
      return span([
        `Resend to phone ending in ${mobile}? `,
        btnLink(actions.resendSmsRequest, "Yes"),
        " / ",
        btnLink(() => this.cancelResend(), "No")
      ]);
    } else {
      return btnLink(() => this.askResend(), "I didn't get the code");
    }
  }

  render() {
    const { form, fields, signin, cancelButton, actions } = this.props;
    const { mobile } = signin;
    const className = c("smsauth", {
      loading: signin.isLoading,
      hasError: signin.error
    });
    const onSubmit = values => this.handleSubmit(values);

    const recoveryButton = button(
      ".btn-link.field-help",
      {
        type: "button",
        onClick: () => actions.twoFactorStartRecovery(),
        tabIndex: -1
      },
      "I've lost my phone"
    );

    return h(ManagedForm, { form, className, onSubmit }, [
      h(FormError, { text: signin.error }),
      div(
        ".row.note",
        `Enter the code sent to your mobile phone ending in ${mobile}`
      ),
      div(".row", [
        h(TextField, {
          width: 12,
          label: "Code",
          field: fields.twoFactorCode,
          help: recoveryButton,
          autoFocus: true,
          autoComplete: "one-time-code"
        })
      ]),
      div(".row", this.resendState()),
      div(".signin-controls", [
        div(".buttons", [
          h(SubmitButton, { form, text: "Continue" }),
          cancelButton,
        ]),
      ]),
    ]);
  }
}

function btnLink(onClick, text) {
  return button(".btn-llink", { type: "button", onClick }, text);
}

const fields = ["twoFactorCode"];

function validate(values) {
  return validator(values, {
    twoFactorCode: { presence: true }
  });
}

export default form(fields, validate)(TwoFactorSms);
