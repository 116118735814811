import { TransitionGroup, CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { h, fasIcon, numberToCurrency, pluralize } from "h";
import { price } from "checkout/lookup/price";

const isEmpty = items => !items || items.length === 0;

const renderCartItem = onRemove => item =>
  h("li.cart-item", { key: item.name }, [
    h("span.name", item.name),
    h(
      "button.remove_cart",
      {
        title: "Remove from cart",
        onClick: e => onRemove(item.name)
      },
      fasIcon("times")
    ),
    renderSubItems(item.components)
  ]);

function renderSubItems(subItems) {
  if (subItems.length > 0) {
    return h(
      ".sub",
      subItems.map((c, i) =>
        h(".row", { key: i }, [
          h(".label", c.label),
          "\xa0", // for cukes
          h(".amount", price(c.amount, c.regular_amount))
        ])
      )
    );
  }
}

function renderNote(note) {
  return fadeSlide(note ? h(".note", note) : []);
}

function fadeSlide(items) {
  const transition = {
    classNames: "note",
    timeout: {
      enter: 500,
      exit: 500
    }
  };
  return h(
    TransitionGroup,
    mapItems(items, transition) 
  );
}

function fadeSlideList(items) {
  const transition = {
    classNames: "cart-item",
    timeout: {
      enter: 500,
      exit: 500
    }
  }
  return h(
    TransitionGroup,
    {
      component: "ul"
    },   
    mapItems(items, transition)
  );
}

function mapItems(items, transition) {
  return items.map(item => h(CSSTransition, {
    ...transition
  }, item))
}

export function initCartData(cart) {
  return {
    items: cart.cart.map(initCartItem),
    subtotal: cart.net_subtotal,
    note: cart.note,
    voucherEligible: cart.voucher_eligible
  };

  function initCartItem(item, k) {
    return {
      name: item.name,
      amount: item.net_price,
      components: initSubItems(item.components),
      position: k
    };
  }

  function initSubItems(components) {
    let subItems = [],
      subComponents = components;
    const c = components[0];

    if (c.id === "domain") {
      subItems.push({
        label: c.summary,
        amount: c.net_amount,
        regular_amount: c.regular_net_amount
      });
      subComponents = subComponents.slice(1);
    }

    let { count, amount } = getMailboxData(subComponents);
    if (count > 0) {
      subItems.push({
        label: pluralize(count, "Mailbox", "Mailboxes"),
        amount: numberToCurrency(amount)
      });
    }
    return subItems;
  }

  function getMailboxData(components) {
    let count = 0,
      amount = 0;
    components.forEach(c => {
      count += c.num_emails || 0;
      amount += parseFloat(c.amount.replace(/[^\-,\d]/, ""));
    });
    return { count, amount };
  }
}

export function MiniCart({ items, subtotal, note, onRemoveFromCart }) {
  if (isEmpty(items)) {
    return null;
  }
  return h(".minicart", [
    renderNote(note),
    h("h2", "Cart Summary"),
    h(".cart", [
      fadeSlideList(items.map(renderCartItem(onRemoveFromCart))),
      h(".cart_total", [h(".label", "Subtotal"), h(".amount", "USD " + subtotal)]),
      h(".buttons", [h("a.btn.primary", { href: "/cart" }, "Proceed to Cart")])
    ])
  ]);
}

MiniCart.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtotal: PropTypes.string.isRequired,
  note: PropTypes.string,
  onRemoveFromCart: PropTypes.func.isRequired
};
