import PropTypes from "prop-types";
import { Component } from "react";
import { form } from "../form";
import h from "h";
import { div, p, button } from "tags";

import SigninForm from "./SigninForm";
import SigninEmailForm from "./SigninEmailForm";
import SigninTokenForm from "./SigninTokenForm";
import ForgotUsername from "./ForgotUsername";
import ForgotPassword from "./ForgotPassword";
import CreateAccountWizard from "signup/CreateAccountWizard";
import TwoFactorSms from "./TwoFactorSms";
import TwoFactorApp from "./TwoFactorApp";
import TwoFactorEmail from "./TwoFactorEmail";
import TwoFactorRecovery from "./TwoFactorRecovery";
import Crossfade from "./Crossfade";

export class AccountForm extends Component {
  render() {
    const { accountForm, fields, canSkip } = this.props;

    if (accountForm.skipSignin == "confirm") {
      return this.confirmSkipSignin();
    }

    const mode = fields.mode;
    if (mode.value === "signed_in" || mode.value === "tos") {
      return this.confirmSignOut();
    }

    const childForms = this.renderForm();

    return div(".account-form", [
      div([
        h(Crossfade, {
          child1: childForms.signin,
          child2: childForms.createAccount
        })
      ])
    ]);
  }

  confirmSkipSignin() {
    const { skipSignin, cancelSkipSignin } = this.props.actions;
    return h("form", [
      div(".fieldgroup", [
        p(
          "Can't access your account? Need to renew a domain on behalf of someone else? In a big hurry? Hover lets you renew a domain without signing in."
        )
      ]),
      div(".fieldgroup", [
        button(
          ".btn.btn-primary",
          { type: "button", onClick: skipSignin },
          "Yes, skip sign in"
        ),
        button(
          ".btn-link.form_cancel",
          { type: "button", onClick: cancelSkipSignin },
          "Cancel"
        )
      ])
    ]);
  }

  confirmSignOut() {
    const onClick = () => {
      this.props.actions.redirectToNextStep();
      this.props.actions.toggleAccountForm();
    }

    return h("form", { method: "POST", action: "/checkout/signout" }, [
      div(".fieldgroup", [
        p("Sign out to complete this purchase as a different user?")
      ]),
      div(".fieldgroup.buttons", [
        button(
          ".btn-link.form_cancel",
          { type: "button", onClick },
          "Cancel"
        ),
        button(".btn.btn-primary.signout", "Sign out")
      ])
    ]);
  }

  renderForm() {
    const {
      fields,
      canSkip,
      actions,
      accountForm: { formName },
    } = this.props;
    if (formName === "signin" || canSkip) {
      return { signin: this.signinForm() };
    } else {
      const { recaptchaKey, recaptchaSignupEnabled, verificationRequired } = this.props;
      return {
        createAccount: h(CreateAccountWizard, {
          key: "create",
          actions,
          recaptchaKey,
          recaptchaSignupEnabled,
          verificationRequired
        }),
      };
    }
  }

  signinForm() {
    const { signin, actions, recaptchaKey, recaptchaSigninEnabled, magicSigninEnabled } = this.props;
    const cancelButton = this.skipSigninButton();
    const step = signin.step;

    switch (step) {
      case "ask_username_password":
        return h(SigninForm, {
          key: step,
          signin,
          actions,
          cancelButton,
          recaptchaKey,
          recaptchaSigninEnabled,
          magicSigninEnabled,
        });

      case "forgot_username":
        return h(ForgotUsername, {
          key: step,
          signin,
          actions,
          cancelButton,
        });

      case "forgot_password":
        return h(ForgotPassword, {
          key: step,
          signin,
          actions,
          cancelButton,
        });

      case "ask_2fa_sms":
        return h(TwoFactorSms, {
          key: step,
          signin,
          actions,
          cancelButton,
        });

      case "ask_2fa_app":
        return h(TwoFactorApp, {
          key: step,
          signin,
          actions,
          cancelButton,
        });

      case "ask_2fa_email":
        return h(TwoFactorEmail, {
          key: step,
          signin,
          actions,
          cancelButton,
        });
      case "recovery":
        return h(TwoFactorRecovery, {
          key: step,
          signin,
          actions,
          cancelButton,
        });

      case "ask_email": {
        const initialValues = {
          email: signin.email
        }
        return h(SigninEmailForm, { actions, signin, step, initialValues });
      }

      case "ask_token":
        return h(SigninTokenForm, { actions, signin, step });

      default:
        throw new Error("No such step: " + step);
    }
  }

  skipSigninButton() {
    const { canSkip, numSigninFailures, actions } = this.props;
    if (canSkip && numSigninFailures >= 2) {
      return button(
        ".btn-link.form_cancel",
        {
          type: "button",
          onClick: () => actions.confirmSkipSignin()
        },
        "Renew without signing in"
      );
    } else {
      return null;
    }
  }
}

AccountForm.propTypes = {
  actions: PropTypes.object.isRequired,
  canSkip: PropTypes.bool,
  recaptchaKey: PropTypes.string,
  recaptchaSigninEnabled: PropTypes.bool.isRequired,
  recaptchaSignupEnabled: PropTypes.bool.isRequired
};

const fields = ["mode"];

export default form(fields)(AccountForm);
