import { Component } from "react";
import h from "h";
import { p } from "tags";
import { Link } from "react-router";

export default class BadToken extends Component {
  render() {
    return h("form.bad", [
      p(".error", "This link has expired."),
      p([
        h(
          Link,
          { to: "/signin" },
          "Click here to sign in or send a new confirmation email."
        )
      ])
    ]);
  }
}
