import PropTypes from "prop-types";
import React, { Component } from "react";
import { h, fasIcon, farIcon } from "h";
import { Box, BoxItem } from "../elements/box";

export class AsideHelp extends Component {
  render() {
    const { supportOpen } = this.props;

    return h("aside.help", [
      h(Box, { title: "Need last minute help?" }, [
        h(BoxItem, [label(fasIcon("phone"), "1-866-731-6556")]),
        h(BoxItem, [label(farIcon("envelope"), emailLink("help@hover.com"))]),
        h(BoxItem, { className: "hours" }, [label(farIcon("clock"), supportHours())])
      ])
    ]);

    function label(...children) {
      return h(".label", children);
    }

    function emailLink(addr) {
      return h("a", { href: `mailto:${addr}` }, addr);
    }

    function supportHours() {
      return h(".hours", [
        ...hours("Mon - Fri", "8am - 8pm (Eastern)"),
        ...hours("Sat - Sun", "8am - 8pm (Eastern)")
      ]);
    }

    function hours(days, times) {
      return [h(".days", days), h(".times", times)];
    }
  }
}

AsideHelp.propTypes = {
  supportOpen: PropTypes.bool.isRequired
};
