import PropTypes from "prop-types";
import h from "h";
import Summary from "./Summary";

function fullName(first, last) {
  return [first, last].join(" ");
}

export default function AccountInfo({ first_name, last_name, email, username }) {
  return h(Summary, {
    items: [
      ["Name", fullName(first_name, last_name)],

      ["Email", email],

      ["Username", username]
    ]
  });
}

AccountInfo.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};
