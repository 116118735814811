import Immutable from "immutable";

function Signal() {
  let END_OF_CHAIN = new Object();
  let UPDATE = new Object();
  let actionMap = Immutable.Map();

  let obj = { foldp, on, sendAction, endChain: true };
  return obj;

  function foldp(update, initialModel, callback) {
    on(UPDATE, function(action) {
      obj.currentModel = update(action, obj.currentModel);
      callback(obj.currentModel);
    });

    obj.currentModel = initialModel;
    callback(obj.currentModel);
  }

  function sendAction(action) {
    const callbacks = callbacksForAction(action).takeUntil(
      cb => cb === END_OF_CHAIN
    );

    if (callbacks.isEmpty()) {
      throw new Error(`Unknown action ${action}`);
    } else {
      callbacks.forEach(callback =>
        callback(action, obj.currentModel, obj.sendAction)
      );
    }
  }

  function callbacksForAction(action) {
    const name = action.name;
    const assignedCallbacks = actionMap.get(name);
    const defaultCallbacks = actionMap.get(UPDATE, Immutable.List());
    if (assignedCallbacks && defaultCallbacks) {
      return assignedCallbacks.concat(defaultCallbacks);
    } else if (assignedCallbacks) {
      return assignedCallbacks;
    } else {
      return defaultCallbacks;
    }
  }

  function on(actionName, callback, endChain) {
    const originalCallbacks = actionMap.get(actionName, Immutable.List());
    let newCallbacks = originalCallbacks.push(callback);
    if (endChain) {
      newCallbacks = newCallbacks.push(END_OF_CHAIN);
    }
    actionMap = actionMap.set(actionName, newCallbacks);
  }
}

export default Signal;
