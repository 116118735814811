import PropTypes from "prop-types";
import { Component } from "react";
import { div, main } from "tags";
import "../styles/xfade.css"

const steps = [
  {
    tick: 0,
    leaveClass: "xfade-leave",
    enterClass: "xfade-enter",
    enterHeight: (_he, hl) => hl
  },
  {
    tick: 1,
    leaveClass: "xfade-leave xfade-leave-active",
    enterClass: "xfade-enter xfade-enter-active",
    enterHeight: he => he
  },
  {
    tick: 500,
    leaveClass: "",
    enterClass: "",
    enterHeight: () => null
  }
];

class Crossfade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inTransition: false
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.child1 && !this.props.child1) {
      this.fadeInEditor();
    } else if (!newProps.child1 && this.props.child1) {
      this.fadeInViewer();
    }
  }

  fadeInEditor() {
    this.setState({ inTransition: true }, () => {
      this.waitForHeights().then(({ eheight, vheight }) =>
        this.crossFade(true, eheight, vheight)
      );
    });
  }

  fadeInViewer() {
    this.setState({ inTransition: true }, () => {
      this.waitForHeights().then(({ eheight, vheight }) =>
        this.crossFade(false, vheight, eheight)
      );
    });
  }

  waitForHeights() {
    return new Promise(resolve => {
      const loop = step => {
        const { eheight, vheight } = this.state;
        const editorHeightMissing = eheight === undefined;
        const viewerHeightMissing = vheight === undefined; // && stepIsCompleted;
        if (step < 10 && (editorHeightMissing || viewerHeightMissing)) {
          return setTimeout(() => loop(step + 1), 20);
        }

        resolve({ eheight, vheight });
      };
      loop(0);
    });
  }

  crossFade(editorActive, h1, h2) {
    this.setState({
      inTransition: true,
      editorActive: editorActive,
      ...this.stepState(0, h1, h2)
    });

    setTimeout(() => {
      this.setState(this.stepState(1, h1, h2));
    }, 1);

    setTimeout(() => {
      this.setState({
        inTransition: false,
        editorActive: editorActive,
        ...this.stepState(2, h1, h2)
      });
    }, 500);
  }

  stepState(n, h1, h2) {
    const step = steps[n];
    const data = {
      leaveClass: step.leaveClass,
      enterClass: step.enterClass,
      enterStyle: { height: step.enterHeight(h1, h2) },
      leaveStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }
    };
    return data;
  }

  render() {
    const { inTransition } = this.state;
    const { leaveClass, enterClass, leaveStyle, enterStyle } = this.state;
    const { child1, child2, mainStyle = {} } = this.props;
    const editorActive = !!this.props.child1;
    const eref = e => {
      if (e && this.state.eheight !== e.offsetHeight) {
        this.setState({ eheight: e.offsetHeight });
      }
    };
    const vref = e => {
      if (e && this.state.vheight !== e.offsetHeight) {
        this.setState({ vheight: e.offsetHeight });
      }
    };

    if (inTransition) {
      if (editorActive) {
        if (child2) {
          return div(
            ".xfade-height",
            {
              key: "xfade",
              style: { position: "relative", ...enterStyle }
            },
            [
              main(
                ".leaving",
                { key: "child2", className: leaveClass, style: leaveStyle },
                child2
              ),
              main(
                ".entering",
                { key: "child1", ref: eref, className: enterClass },
                child1
              )
            ]
          );
        } else {
          return div(
            ".xfade-height",
            {
              key: "xfade",
              style: { position: "relative", ...enterStyle }
            },
            [
              div(".leaving", {
                key: "child2",
                className: leaveClass,
                style: leaveStyle
              }),
              main(
                ".entering",
                { key: "child1", ref: eref, className: enterClass },
                child1
              )
            ]
          );
        }
      } else {
        if (!child2) {
          return div(
            ".xfade-height",
            {
              key: "xfade",
              style: { position: "relative", ...enterStyle }
            },
            [
              main(
                ".leaving",
                { key: "child1", className: leaveClass, style: leaveStyle },
                child1
              ),
              div(".entering", { key: "child2", className: enterClass })
            ]
          );
        } else {
          return div(
            ".xfade-height",
            {
              key: "xfade",
              style: { position: "relative", ...enterStyle }
            },
            [
              main(
                ".leaving",
                { key: "child1", className: leaveClass, style: leaveStyle },
                child1
              ),
              main(
                ".entering",
                { key: "child2", ref: vref, className: enterClass },
                child2
              )
            ]
          );
        }
      }
    } else {
      if (editorActive) {
        if (child2) {
          return div({ key: "xfade" }, [
            main({ key: "child1", ref: eref, style: mainStyle }, child1),
            main({ key: "child2", style: { display: "none" } }, child2)
          ]);
        } else {
          return div({ key: "xfade" }, [
            main({ key: "child1", ref: eref, style: mainStyle }, child1),
            div({ key: "child2", style: { display: "none" } })
          ]);
        }
      } else {
        if (child2) {
          return div({ key: "xfade" }, [
            main({ key: "child2", ref: vref, style: mainStyle }, child2),
            main({ key: "child1", style: { display: "none" } }, child1)
          ]);
        } else {
          return div({ key: "xfade" }, [
            div({ key: "child2", ref: vref, style: { display: "none" } }),
            main({ key: "child1", style: { display: "none" } }, child1)
          ]);
        }
      }
    }
  }
}

Crossfade.propTypes = {
  child1: PropTypes.node,
  child2: PropTypes.node,
  mainStyle: PropTypes.object
};

export default Crossfade;
