import get from "lodash/get";
import { xhrPost, xhrPut, xhrGet } from "common/xhr";
import { openPaypal, openApplePay } from "../braintree";
import * as CheckoutActions from "../actions/checkout_actions";
import * as SigninActions from "../actions/signinActions";
import { verifyEmail } from "email_verification/requests";

export default store => next => action => {
  const type = action.type;

  const result = next(action);
  if (!action.remote) {
    return result;
  }

  switch (type) {
    case "SIGNIN_REQUEST": {
      const { username, password, remember, token } = action;
      return handleSigninRequest(username, password, remember, token, store.dispatch);
    }

    case "SUCCESSFUL_SIGNIN":
      return handleSuccessfulSignin(action.data, store.dispatch);

    case "OPT_IN_NEWSLETTER":
      return handleOptInNewsletter(action.payload);

    case "CREATE_ACCOUNT":
      return handleCreateAccount(action.payload, store.dispatch);

    case "AGREE_TO_TERMS":
      return handleAgreeToTerms(store.dispatch);

    case "SKIP_SIGNIN":
      return handleSkipSignin(true, store.dispatch);

    case "UNSKIP_SIGNIN":
      return handleSkipSignin(false, store.dispatch);

    case "SUBMIT_PASSWORD_RECOVERY":
      return handlePasswordRecovery(action.username, store.dispatch);

    case "SUBMIT_REGISTRATION_DATA":
      return handleSubmitRegistrationData(action.payload, store.dispatch);

    case "SUBMIT_TLD_DATA":
      return handleSubmitTldData(action.payload, store.dispatch);

    case "SUBMIT_MAILBOX_DATA":
      return handleSubmitMailboxData(action.payload, store.dispatch);

    case "SUBMIT_BILLING_DATA":
      return handleSubmitBillingData(action.payload, store.dispatch);

    case "PP_OPEN":
      return openPaypal(action.options);

    case "PP_COMPLETED":
      return handleSubmitPaypalBilling(
        action.payload,
        action.options,
        store.dispatch
      );

    case "AP_OPEN":
      return openApplePay(action.options);

    case "AP_COMPLETED":
      return handleSubmitApplePayBilling(
        action.payload,
        action.options,
        store.dispatch
      );

    case "SUBMIT_ORDER":
      return handleSubmitOrder(action.payload, store.dispatch);

    case "AUTH_2FA_REQUEST":
      return handleTwoFactorRequest(action.code, store);

    case "RESEND_SMS_REQUEST":
      return handleResendSms(store.dispatch);

    case "2FA_RECOVERY_REQUEST":
      return handleTwoFactorRecovery(action.recoveryCode, store.dispatch);

    case "MAIN_SIGNIN_SUCCESS":
      return handleMainSigninSuccess(action.data, store);

    case "SIGNIN_SUBMIT_VERIFICATON":
      return handleVerificationRequest(action.payload, store);

    case "INIT_TOKEN_REQUEST":
      return handleTokenSigninRequest(action.email, store.dispatch);

    case "INIT_RESEND_TOKEN_REQUEST":
      return handleResendTokenSigninRequest(action.email, store.dispatch);

    case "SUBMIT_TOKEN_REQUEST":
      return handleSubmitTokenRequest(action.token, store);

    case "INIT_USER_SELECT_REQUEST":
      return handleInitUserSelectRequest(action.username, store);

    default:
      if (process.env.NODE_ENV === "production") {
        throw new Error("Unknown remote action: " + JSON.stringify(action));
      } else {
        console.error("Unknown remote action:", action); // eslint-disable-line no-console
      }
  }
};

function handleSigninRequest(username, password, remember, token, dispatch) {
  return xhrPost("/signin/auth.json", { username, password, remember, token })
    .then(data => {
      if (data.status === "completed") {
        // i.e., not 2fa
        return Promise.resolve(data);
      } else {
        dispatch(SigninActions.signinResponse(data));
        return Promise.resolve(data);
      }
    })
    .catch(resp => {
      const errors = resp.errors || {
        _error: "An error occurred. Please try again."
      };
      dispatch(
        SigninActions.signinResponse({
          status: "declined",
          error: errors._error
        })
      );
      return Promise.resolve({ succeeded: false });
    });
}

async function handleTokenSigninRequest(email, dispatch) {
  try {
    const data = await xhrPost("/signin/send_signin_token", { email })
    dispatch(SigninActions.initTokenResponse(data));
  } catch (error) {
    dispatch(SigninActions.setTokenError(error.errors));
  }
}

async function handleResendTokenSigninRequest(email, dispatch) {
  try {
    const data = await xhrPost("/signin/resend_signin_token", { email })
    dispatch(SigninActions.initTokenResponse(data));
  } catch (error) {
    dispatch(SigninActions.setTokenError(error.errors));
  }
}

async function handleSubmitTokenRequest(token, store) {
  const { email, remember } = store.getState().signin;
  store.dispatch(SigninActions.clearTokenError())
  try {
    const data = await xhrPost("/signin/verify_signin_token", { email, remember, token });
    if (data.status === "completed") {
      // i.e., not 2fa
      return Promise.resolve(data);
    } else {
      store.dispatch(SigninActions.signinResponse(data));
      return Promise.resolve(data);
    }
  } catch (error) {
    store.dispatch(SigninActions.setTokenError(error.errors))
  }
}

async function handleInitUserSelectRequest(username, store) {
  const { email, remember, token } = store.getState().signin;
  try {
    const data = await xhrPost("/signin/select_user", { username, email, remember, token });
    if (data.status === "completed") {
      // i.e., not 2fa
      return Promise.resolve(data);
    } else {
      store.dispatch(SigninActions.signinResponse(data));
      return Promise.resolve(data);
    }
  } catch (error) {
    store.dispatch(SigninActions.setTokenError(error.errors));
  }
}

function handleSuccessfulSignin(data, dispatch) {
  xhrGet("/checkout/state.json").then(data => {
    dispatch(CheckoutActions.refreshCheckoutState(data));
    if (get(data, "step_data.account.data.mode") === "tos") {
      dispatch(SigninActions.toggleAccountForm());
    }
    dispatch(CheckoutActions.redirectToNextStep());
    return Promise.resolve();
  });
}

function handleTwoFactorRequest(code, store) {
  const { remember } = store.getState().signin;
  return xhrPost("/signin/auth2.json", { code, remember }).catch(err => {
    store.dispatch(
      SigninActions.twoFactorResponse({
        succeeded: false,
        error: err.errors._error
      })
    );
    // If we don't return a rejected promise here, 2FA will try to continue on with
    // MainSigninSuccess. This doesn't allow the user to log in, but it shows an error
    // in the JS console.
    return Promise.reject(err.errors._error)
  });
}

function handleTwoFactorRecovery(recovery_code, dispatch) {
  return xhrPost("/signin/auth2.json", { recovery_code }).catch(resp =>
    dispatch(
      SigninActions.twoFactorRecoveryResponse({
        succeeded: false,
        error: resp.errors._error
      })
    )
  );
}

function handleResendSms(dispatch) {
  return xhrPost("/signin/resend_code.json")
    .then(data => dispatch(SigninActions.resendSmsResponse(data)))
    .catch(() => dispatch(SigninActions.signinRemoteError("Could not send code.")));
}

function handleCreateAccount(accountData, dispatch) {
  return xhrPost("/checkout/create_account.json", { account: accountData })
    .then(data => dispatch(CheckoutActions.refreshCheckoutState(data.checkout)))
    .then(() => dispatch(CheckoutActions.redirectToNextStep()))
    .then(() => dispatch(CheckoutActions.optInNewsletter(accountData.tosValues)))
    .catch(resp => Promise.reject(resp.errors));
}

function handleOptInNewsletter(data) {
  const optInPayload = {
    email_notifications: {
      newsletters: true,
      consent: true,
      tag: "tos"
    }
  }
  if (data.newsletter) {
    return xhrPut("/api/settings", optInPayload)
      .catch(resp => Promise.reject(resp.errors));
  }
}

function handleAgreeToTerms(dispatch) {
  return xhrPost("/checkout/agree_to_terms.json")
    .then(data => dispatch(CheckoutActions.refreshCheckoutState(data.checkout)))
    .then(() => dispatch(CheckoutActions.redirectToNextStep()))
    .catch(resp => Promise.reject(resp.errors));
}

function handlePasswordRecovery(username, dispatch) {
  return xhrPost("/signin/recover_password.json", { username })
    .then(response => dispatch(SigninActions.passwordRecoveryResponse(response)))
    .catch(() => dispatch(SigninActions.signinRemoteError("Could not send email.")));
}

function handleSubmitRegistrationData(data, dispatch) {
  return formPost("/checkout/save_registration", { registration: data }, dispatch);
}

function handleSubmitTldData(data, dispatch) {
  return formPost("/checkout/save_tld", data, dispatch);
}

function handleSubmitMailboxData(data, dispatch) {
  return formPost("/checkout/save_mailbox", { mailbox: data }, dispatch);
}

function handleSubmitBillingData(data, dispatch) {
  return formPost("/checkout/save_billing", { billing: data }, dispatch);
}

function handleSubmitPaypalBilling(data, options, dispatch) {
  return formPost(
    "/checkout/save_paypal_billing",
    { paypal: { ...data, ...options } },
    dispatch
  );
}

function handleSubmitApplePayBilling(data, options, dispatch) {
  return formPost(
    "/checkout/save_apple_pay_billing",
    { apple_pay: { ...data, ...options } },
    dispatch
  );
}

function handleSubmitOrder(data, dispatch) {
  dispatch(CheckoutActions.startLoading());
  return xhrPost("/checkout/submit_order", data)
    .then(response => {
      document.location = response.url;
    })
    .catch(resp => {
      const errors = resp.errors;
      if (errors.page) {
        return xhrGet("/checkout/state.json")
          .then(data => dispatch(CheckoutActions.refreshCheckoutState(data)))
          .then(() => dispatch(CheckoutActions.goto(errors.page, errors)));
      } else {
        return Promise.reject(errors);
      }
    })
    .finally(() => dispatch(CheckoutActions.stopLoading()));
}

function handleSkipSignin(skip, dispatch) {
  return xhrPost("/checkout/skip_signin", { skip })
    .then(data => {
      if (!skip) {
        data.checkout.step_data.account.data.mode = "signin";
      }
      return dispatch(CheckoutActions.refreshCheckoutState(data.checkout));
    })
    .then(() => dispatch(CheckoutActions.redirectToNextStep()))
    .catch(() => {
      document.location.href = "/checkout";
    });
}

function formPost(url, data, dispatch) {
  dispatch(CheckoutActions.startLoading());
  return xhrPost(url, data)
    .then(response => {
      if (response.redirect) {
        location.href = response.redirect;
        return new Promise(() => {}); // never resolves
      } else if (response.succeeded) {
        return Promise.resolve(response.checkout);
      } else {
        return Promise.reject(response.errors);
      }
    })
    .then(checkout => {
      dispatch(CheckoutActions.refreshCheckoutState(checkout));
      return dispatch(CheckoutActions.redirectToNextStep());
    })
    .catch(resp => {
      if (resp.errors) {
        return Promise.reject(resp.errors);
      } else {
        return Promise.reject({
          _error: "Unable to communicate with server. Please try again."
        });
      }
    })
    .finally(() => dispatch(CheckoutActions.stopLoading()));
}

function handleMainSigninSuccess(data, store) {
  if (data && !data.email_verified && store.getState().signin.verificationEnabled) {
    store.dispatch(SigninActions.showEmailConfirmation({
      email: data.email,
      emailVerified: data.email_verified,
      url: data.url,
      newsletters: data.newsletters
    }));
  } else if (data.url) {
    document.location.href = data.url;
  }
}

async function handleVerificationRequest(payload, store) {
  const state = store.getState();
  const { url, ...verification } = {...state.signin.verification, ...payload};
  try {
    await verifyEmail({ code: verification.code, email: verification.email });
    await xhrPut("/api/settings", { ...verification, email_verified: true, tag: "signin" });
    // if we're at /signin then we want to go to the checkup page
    if (window.location.href.endsWith("/signin")) {
      window.location.href = "/control_panel/settings/checkup";
    } else {
      window.location.href = url;
    }
  } catch (e) {
    const error = e.code || Object.values(e.errors)[0]
    store.dispatch(SigninActions.setVerificationError(error))
  }
}
